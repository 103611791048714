<template>
  <!-- Add, Edit, Delete -->
  <MasterComponent
    :data="data" />
</template>

<script>
import MasterComponent from '@/components/Page/MasterComponent'
import * as Yup from 'yup'
export default {
  name: 'SettingView',
  components: {
    MasterComponent
  },
  created () {
  },
  data () {
    return {
      data: {
        title: 'Settings',
        slug: 'setting/admin',
        config: {
          stats: false,
          datatable: false
        },
        actions: [
          'edit'
        ],
        columns: [
          {
            label: 'Name',
            slug: 'name',
            type: 'text'
          },
          {
            label: 'Value',
            slug: 'value',
            type: 'text',
            field: {
              name: 'value',
              as: 'input',
              type: 'text',
              rules: Yup.string().required('Value is a required field').nullable()
            }
          },
          {
            label: 'Action',
            slug: 'action',
            width: '80px',
            type: 'action',
            actions: [
              {
                id: 1,
                label: 'Edit',
                slug: 'edit'
              }
            ]
          }
        ]
      },
      activeTab: 'All',
      searchBox: false
    }
  }
}
</script>
