// import 'bootstrap/dist/css/bootstrap.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { outside } from '@/plugins/outside'
import VueSimpleAlert from 'vue3-simple-alert-next'

import 'bootstrap/dist/js/bootstrap.js'

const moment = require('moment')
require('moment/locale/en-gb')
moment.locale('en-gb')

const momentTimezone = require('moment-timezone')

const myPlugin = {
  install (app, options) {
    app.config.globalProperties.$centsToDollar = (cents) => {
      if (!cents) {
        return '$0'
      }
      return (parseInt(cents) / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })
    }
    app.config.globalProperties.$timestampToDateDefault = (timestamp) => {
      if (moment(timestamp, 'X', true).isValid()) {
        return moment.unix(timestamp).format('YYYY-MM-DD')
      } else {
        return moment(timestamp).format('YYYY-MM-DD')
      }
    }
    app.config.globalProperties.$timestampToDate = (timestamp) => {
      if (moment(timestamp, 'X', true).isValid()) {
        return moment.unix(timestamp).format('MMM D, YYYY')
      } else {
        return moment(timestamp).format('MMM D, YYYY')
      }
    }
    app.config.globalProperties.$timestampToMonthDay = (timestamp) => {
      if (moment(timestamp, 'X', true).isValid()) {
        return moment.unix(timestamp).format('D MMMM')
      } else {
        return moment(timestamp).format('D MMMM')
      }
    }
    app.config.globalProperties.$timestampToTime12 = (timestamp) => {
      if (moment(timestamp, 'X', true).isValid()) {
        return moment.unix(timestamp).format('h:mm a')
      } else {
        return moment(timestamp).format('h:mm a')
      }
    }
    app.config.globalProperties.$timestampToTime24 = (timestamp) => {
      if (moment(timestamp, 'X', true).isValid()) {
        return moment.unix(timestamp).format('H:mm')
      } else {
        return moment(timestamp).format('H:mm')
      }
    }
    app.config.globalProperties.$timezoneWithAbbr = (timestamp) => {
      const zoneName = momentTimezone.tz.guess()
      // const timeZone = momentTimezone.tz(zoneName).zoneAbbr()

      const timeZoneName = Intl.DateTimeFormat('en', {
        timeZoneName: 'short',
        timeZone: zoneName
      })
        .formatToParts()
        .find((i) => i.type === 'timeZoneName').value

      return `${zoneName} (${timeZoneName})`
    }
  }
}

createApp(App)
  .use(myPlugin)
  .use(VueSimpleAlert)
  .use(store)
  .use(router)
  .directive('outside', outside)
  .mount('#app')
