export default function authHeader (hasFile = false) {
  const user = JSON.parse(localStorage.getItem('user'))

  if (user && user.token) {
    return {
      // 'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      'Content-Type': hasFile ? 'multipart/form-data' : 'application/json',
      // 'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${user.token}`
    }
  } else {
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  }
}
