<template>
  <div
    v-if="!loading.created"
    class="d-flex justify-content-center align-items-center w-100" style="height: calc(100vh - 48px)">
    <div
      v-show="loading"
      class="spinner-border">
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">
        <h1 class="view-name">Dashboard</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-3">
        <router-link
          to="/orders"
          class="text-decoration-none"
          aria-current="page">
          <div class="card border-0 mb-3 card-anchor">
            <div class="card-header bg-transparent border-0">Pending Orders</div>
            <div class="card-body">
              <h5 class="card-title">{{ data.general_stats.orders }}</h5>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-md-3">
        <router-link
          to="/orders"
          class="text-decoration-none"
          aria-current="page">
          <div class="card border-0 mb-3 card-anchor">
            <div class="card-header bg-transparent border-0">Pending Tickets</div>
            <div class="card-body">
              <h5 class="card-title">{{ data.general_stats.tickets }}</h5>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-md-3">
        <router-link
          to="/orders"
          class="text-decoration-none"
          aria-current="page">
          <div class="card border-0 mb-3 card-anchor">
            <div class="card-header bg-transparent border-0">Online Users</div>
            <div class="card-body">
              <h5 class="card-title">{{ data.general_stats.users_live }}</h5>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-md-3">
        <router-link
          to="/orders"
          class="text-decoration-none"
          aria-current="page">
          <div class="card border-0 mb-3 card-anchor">
            <div class="card-header bg-transparent border-0">Net Income</div>
            <div class="card-body">
              <h5 class="card-title">{{ centsToDollar(data.general_stats.total_income) }}</h5>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h1 class="view-name mt-5">Statistics</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="card border-0 mb-3">
          <div class="card-header bg-transparent border-0">
            <div class="d-flex justify-content-between">
              <div class="card-header-title card-header-custom">Users</div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table align-middle">
                <tbody>
                <tr>
                  <td>Total Users</td>
                  <td class="fw-bold">{{ data.statistics.user.all }}</td>
                </tr>
                <tr>
                  <td>Verified Users</td>
                  <td class="fw-bold">{{ data.statistics.user.verified }}</td>
                </tr>
                <tr>
                  <td>Unverified Users</td>
                  <td class="fw-bold">{{ data.statistics.user.unVerified }}</td>
                </tr>
                <tr>
                  <td>Suspended Users</td>
                  <td class="fw-bold">{{ data.statistics.user.suspended }}</td>
                </tr>
                <tr>
                  <td>Banned Users</td>
                  <td class="fw-bold">{{ data.statistics.user.banned }}</td>
                </tr>
                <tr>
                  <td>Premium Users</td>
                  <td class="fw-bold">{{ data.statistics.user.premium }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card border-0 mb-3">
          <div class="card-header bg-transparent border-0">
            <div class="d-flex justify-content-between">
              <div class="card-header-title card-header-custom">Deposits</div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table align-middle">
                <tbody>
                <tr>
                  <td>Total Transactions</td>
                  <td class="fw-bold">{{ data.statistics.deposit.all }}</td>
                </tr>
                <tr>
                  <td>Total Deposited</td>
                  <td class="fw-bold">{{ centsToDollar(data.statistics.deposit.total_deposits) }}</td>
                </tr>
                <tr>
                  <td>Total Fees</td>
                  <td class="fw-bold">{{ centsToDollar(data.statistics.deposit.total_fees) }}</td>
                </tr>
                <tr>
                  <td>Manual Deposits</td>
                  <td class="fw-bold">{{ centsToDollar(data.statistics.deposit.manual_deposits) }}</td>
                </tr>
                <tr>
                  <td>Refunded</td>
                  <td class="fw-bold">{{ centsToDollar(data.statistics.deposit.refunded) }}</td>
                </tr>
                <tr>
                  <td>Failed</td>
                  <td class="fw-bold">{{ centsToDollar(data.statistics.deposit.failed) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card border-0 mb-3">
          <div class="card-header bg-transparent border-0">
            <div class="d-flex justify-content-between">
              <div class="card-header-title card-header-custom">Affiliates</div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table align-middle">
                <tbody>
                <tr>
                  <td>Total Users</td>
                  <td class="fw-bold">{{ data.statistics.affiliate.all }}</td>
                </tr>
                <tr>
                  <td>Verified Users</td>
                  <td class="fw-bold">{{ data.statistics.affiliate.verified }}</td>
                </tr>
                <tr>
                  <td>Unverified Users</td>
                  <td class="fw-bold">{{ data.statistics.affiliate.unVerified }}</td>
                </tr>
                <tr>
                  <td>Suspended Users</td>
                  <td class="fw-bold">{{ data.statistics.affiliate.suspended }}</td>
                </tr>
                <tr>
                  <td>Total Deposit</td>
                  <td class="fw-bold">{{ centsToDollar(data.statistics.affiliate.deposits) }}</td>
                </tr>
                <tr>
                  <td>Total Expense</td>
                  <td class="fw-bold">{{ centsToDollar(data.statistics.affiliate.expense) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="card border-0 mb-3">
          <div class="card-header bg-transparent border-0">
            <div class="d-flex justify-content-between">
              <div class="card-header-title card-header-custom">Orders</div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table align-middle">
                <tbody>
                <tr>
                  <td>Total Orders</td>
                  <td class="fw-bold">{{ data.statistics.order.all }}</td>
                </tr>
                <tr>
                  <td>Pending Orders</td>
                  <td class="fw-bold">{{ data.statistics.order.pending }}</td>
                </tr>
                <tr>
                  <td>In-progress Orders</td>
                  <td class="fw-bold">{{ data.statistics.order.inProgress }}</td>
                </tr>
                <tr>
                  <td>Completed Orders</td>
                  <td class="fw-bold">{{ data.statistics.order.completed }}</td>
                </tr>
                <tr>
                  <td>Cancelled Orders</td>
                  <td class="fw-bold">{{ data.statistics.order.cancelled }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="card border-0 mb-3">
          <div class="card-header bg-transparent border-0">
            <div class="d-flex justify-content-between">
              <div class="card-header-title card-header-custom">Tickets</div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table align-middle">
                <tbody>
                <tr>
                  <td>Total Tickets</td>
                  <td class="fw-bold">{{ data.statistics.ticket.all }}</td>
                </tr>
                <tr>
                  <td>Pending Tickets</td>
                  <td class="fw-bold">{{ data.statistics.ticket.pending }}</td>
                </tr>
                <tr>
                  <td>Answered Tickets</td>
                  <td class="fw-bold">{{ data.statistics.ticket.answered }}</td>
                </tr>
                <tr>
                  <td>Closed Tickets</td>
                  <td class="fw-bold">{{ data.statistics.ticket.closed }}</td>
                </tr>
                <tr>
                  <td>High Priority</td>
                  <td class="fw-bold">{{ data.statistics.ticket.highPriority }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h1 class="view-name mt-5">Analytics</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-8">
        <div class="card border-0 mb-3 h-100">
          <div class="card-header bg-transparent border-0">
            <div class="d-flex justify-content-between">
              <div class="card-header-title card-header-custom">Statistics</div>
              <div class="card-header-button">
                <span @click="changeTab('Top Browsers')" :class="this.isActive('Top Browsers') ? 'active' : null">Top Browsers</span>
                <span @click="changeTab('Top Countries')" :class="this.isActive('Top Countries') ? 'active' : null">Top Countries</span>
                <span @click="changeTab('Latest Users')" :class="this.isActive('Latest Users') ? 'active' : null">Latest Users</span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <TopBrowsers v-if="isActive('Top Browsers')"/>
            <TopCountries v-if="isActive('Top Countries')"/>
            <LatestUsers v-if="isActive('Latest Users')"/>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="card border-0 mb-3 h-100">
          <div class="card-header card-header-custom bg-transparent border-0">Recent Activity</div>
          <div class="card-body pt-4 pb-4">
            <a
              v-for="(item, idx) in data.analytics.recent_activity"
              :key="idx"
              class="d-flex text-decoration-none pe-3 anchor anchor-announcement">
              {{ item.causer_name }} {{ item.event }} {{ getSlugFromSubject(item.subject) }} on {{ timestampToDate(item.created_at) }}, {{ timestampToTime12(item.created_at) }}
            </a>
            <router-link
              :to="{ name: 'activity' }"
              class="d-flex justify-content-end text-decoration-none pe-3 anchor"
              aria-current="page">
              View All
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBrowsers from '@/components/Dashboard/TopBrowsers.vue'
import TopCountries from '@/components/Dashboard/TopCountries.vue'
import LatestUsers from '@/components/Dashboard/LatestUsers.vue'
import axios from 'axios'
import baseUrl from '@/config/baseUrl.config'
import authHeader from '@/config/authHeader.config'

export default {
  name: 'DashboardView',
  components: {
    TopBrowsers,
    TopCountries,
    LatestUsers
  },
  created () {
    axios
      .get(baseUrl().api + 'dashboard/admin', {
        headers: authHeader()
      })
      .then(res => {
        this.data = res.data
        this.loading.created = true
        console.log(res.data)
      })
      .catch(err => {
        return Promise.reject(err.response.data)
      })
  },
  data () {
    return {
      loading: {
        created: false
      },
      data: null,
      activeTab: 'Top Browsers'
    }
  },
  methods: {
    changeTab (tab) {
      this.activeTab = tab
    },
    isActive (tab) {
      return this.activeTab === tab
    },
    getSlugFromSubject ($subject) {
      if ($subject.uid !== undefined) {
        return $subject.uid
      }

      return null
    },

    /* Static global helpers */
    centsToDollar (cents) {
      return this.$centsToDollar(cents)
    },
    timestampToDate (timestamp) {
      return this.$timestampToDate(timestamp)
    },
    timestampToTime12 (timestamp) {
      return this.$timestampToTime12(timestamp)
    }
  }
}
</script>

<style scoped>
.anchor-announcement {
  font-size: 14px;
  margin-bottom: 16px;
}
</style>
