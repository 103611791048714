<template>
  <div class="navigation-view">
    <div class="d-none d-md-flex flex-column flex-shrink-0 bg-light vh-100 position-fixed top-0" style="width: 230px;">
      <a class="navbar-brand pt-4" href="#">
        <img
          src="@/assets/logo.svg"
          alt=""
          width="94"
          height="22"
          loading="lazy"
        />
      </a>
      <ul class="nav nav-pills flex-column mb-auto text-start pt-4">

        <li class="nav-item pt-3">
          <router-link
            to="/"
            class="nav-link ps-4 pe-4"
            aria-current="page">
            <!--          <img class="bi me-2" src="@/assets/ic-dashboard.svg">-->
            <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#495c77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
            Dashboard
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            to="/users"
            class="nav-link ps-4 pe-4"
            aria-current="page">
            <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#495c77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
            Users
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            to="/orders"
            class="nav-link ps-4 pe-4"
            aria-current="page">
            <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#495c77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><circle cx="10" cy="20.5" r="1"/><circle cx="18" cy="20.5" r="1"/><path d="M2.5 2.5h3l2.7 12.4a2 2 0 0 0 2 1.6h7.7a2 2 0 0 0 2-1.6l1.6-8.4H7.1"/></svg>
            Orders
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            to="/tickets"
            class="nav-link ps-4 pe-4"
            aria-current="page">
            <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#495c77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"/><path d="M14 3v5h5M16 13H8M16 17H8M10 9H8"/></svg>
            Tickets
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            to="/deposits"
            class="nav-link ps-4 pe-4"
            aria-current="page">
            <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#495c77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
            Deposits
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            to="/affiliates"
            class="nav-link ps-4 pe-4"
            aria-current="page">
            <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#495c77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line></svg>
            Affiliates
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            to="/categories"
            class="nav-link ps-4 pe-4"
            aria-current="page">
            <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#495c77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
            Categories
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            to="/services"
            class="nav-link ps-4 pe-4"
            aria-current="page">
            <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#495c77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="2" width="20" height="8" rx="2" ry="2"></rect><rect x="2" y="14" width="20" height="8" rx="2" ry="2"></rect><line x1="6" y1="6" x2="6.01" y2="6"></line><line x1="6" y1="18" x2="6.01" y2="18"></line></svg>
            Services
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'coupon' }"
            class="nav-link ps-4 pe-4"
            aria-current="page">
            <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#495c77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="2" width="20" height="8" rx="2" ry="2"></rect><rect x="2" y="14" width="20" height="8" rx="2" ry="2"></rect><line x1="6" y1="6" x2="6.01" y2="6"></line><line x1="6" y1="18" x2="6.01" y2="18"></line></svg>
            Coupons
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'emailTemplate' }"
            class="nav-link ps-4 pe-4"
            aria-current="page">
            <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#495c77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
            Email Templates
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'newsletter' }"
            class="nav-link ps-4 pe-4"
            aria-current="page">
            <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#495c77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="4"></circle><path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path></svg>
            Mass Email
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'announcement' }"
            class="nav-link ps-4 pe-4"
            aria-current="page">
            <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#495c77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path></svg>
            Announcements
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'activity' }"
            class="nav-link ps-4 pe-4"
            aria-current="page">
            <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#495c77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"/><path d="M14 3v5h5M16 13H8M16 17H8M10 9H8"/></svg>
            Activity
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'setting' }"
            class="nav-link ps-4 pe-4"
            aria-current="page">
            <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#495c77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
            Setting
          </router-link>
        </li>
      </ul>
      <!--    <hr>-->
      <div class="dropdown mb-3 position-fixed bottom-0" style="width: 230px;">
        <ul class="nav nav-pills flex-column mb-auto text-start">
          <li class="nav-item">
            <a
              @click.prevent="logOut"
              class="nav-link ps-4 pe-4 d-flex justify-content-start align-items-center cursor-pointer" aria-current="page">
              <img class="bi me-2" src="@/assets/ic-logout.svg">
<!--              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#CCDDFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M16 17l5-5-5-5M19.8 12H9M13 22a10 10 0 1 1 0-20"/></svg>-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#495c77" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M16 17l5-5-5-5M19.8 12H9M13 22a10 10 0 1 1 0-20"/></svg>-->
              Logout
            </a>
          </li>
        </ul>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'TopBar',
  methods: {
    logOut () {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push({ name: 'login' })
      })
    }
  }
}
</script>

<style scoped>
.navbar-text {
  color: var(--text-orange);
  font-size: 11px;
  font-weight: 600;
}

.bg-light,
.navbar {
  background-color: var(--white) !important;
  /*box-shadow: 0 1px 4px rgb(21 34 50 / 15%);*/
}

button {
  font-size: 16px;
  font-weight: 600;
  color: var(--green-default);
  border: 1px solid var(--green-default);
  padding: 0.075rem 0.75rem;
}

main {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
}

.b-example-divider {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.bi {
  vertical-align: -.125em;
  pointer-events: none;
  fill: currentColor;
}

.dropdown-toggle {
  outline: 0;
}

.nav-flush .nav-link {
  border-radius: 0;
}

.btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: .25rem .5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, .65);
  background-color: transparent;
  border: 0;
}

.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(0, 0, 0, .85);
  background-color: #d2f4ea;
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform .35s ease;
  transform-origin: .5em 50%;
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(0, 0, 0, .85);
}

.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  display: inline-flex;
  padding: .1875rem .5rem;
  margin-top: .125rem;
  margin-left: 1.25rem;
  text-decoration: none;
}

.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: #d2f4ea;
}

.scrollarea {
  overflow-y: auto;
}

.fw-semibold {
  font-weight: 600;
}

.lh-tight {
  line-height: 1.25;
}

.nav-link {
  color: var(--text-default);
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bg-blue);
  background-color: var(--hover-bg-default);
  border: unset;
}

.nav-pills .nav-link:hover {
  background-color: var(--hover-bg-default);
  padding-left: 35px!important;
  transition: 0.3s;
}

.nav-pills .nav-link {
  font-weight: 600;
}

.nav-pills .nav-link {
  border-radius: unset;
}
</style>
