<template>
  <div class="table-responsive">
    <table class="table align-middle">
      <thead>
      <tr>
        <th scope="col">Profile</th>
        <th scope="col">Email</th>
        <th scope="col">IP</th>
        <th scope="col">Registered On</th>
        <th scope="col" style="width: 80px">Status</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Abdullah</td>
        <td>abdullah@sixerr.com</td>
        <td>208.123.123.123</td>
        <td>May 12, 2022<br><span>12:05 PM</span></td>
        <td>
          <button type="button" class="btn btn-status btn-pending">Pending</button>
        </td>
      </tr>
      <tr>
        <td>Abdullah</td>
        <td>abdullah@sixerr.com</td>
        <td>208.123.123.123</td>
        <td>May 12, 2022<br><span>12:05 PM</span></td>
        <td>
          <button type="button" class="btn btn-status btn-pending">Pending</button>
        </td>
      </tr>
      <tr>
        <td>Abdullah</td>
        <td>abdullah@sixerr.com</td>
        <td>208.123.123.123</td>
        <td>May 12, 2022<br><span>12:05 PM</span></td>
        <td>
          <button type="button" class="btn btn-status btn-pending">Pending</button>
        </td>
      </tr>
      <tr>
        <td>Abdullah</td>
        <td>abdullah@sixerr.com</td>
        <td>208.123.123.123</td>
        <td>May 12, 2022<br><span>12:05 PM</span></td>
        <td>
          <button type="button" class="btn btn-status btn-pending">Pending</button>
        </td>
      </tr>
      <tr>
        <td>Abdullah</td>
        <td>abdullah@sixerr.com</td>
        <td>208.123.123.123</td>
        <td>May 12, 2022<br><span>12:05 PM</span></td>
        <td>
          <button type="button" class="btn btn-status btn-pending">Pending</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <router-link
    to="/orders"
    class="d-flex justify-content-end text-decoration-none pe-3 anchor"
    aria-current="page">
    View All
  </router-link>
</template>

<script>
export default {
  name: 'TopCountries'
}
</script>

<style scoped>

</style>
