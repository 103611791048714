<template>
  <div class="card-header-button card-header-button-left">
    <span
      v-for="row in rows"
      :key="row.id"
      @click="changeStatus(row.id)"
      :class="this.active === row.id ? 'active' : null">
      {{ row.label }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'StatusModule',
  props: ['rows'],
  data () {
    return {
      active: null
    }
  },
  methods: {
    changeStatus (id) {
      this.$emit('status', id)
      this.active = id
    }
  }
}
</script>

<style scoped>

</style>
