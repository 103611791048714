<template>
  <div
    v-outside:click="{ exclude: ['.ignore-outside-click'], handler: 'clickOutside' }"
    class="modalForm">
    <div
      v-if="!created"
      class="d-flex justify-content-center align-items-center h-100">
      <span class="spinner-border spinner-border">
      </span>
    </div>
    <Form
      v-if="created"
      @submit="submitForm">
      <div class="mb-4">
        <SimpleInput
          as="input"
          label="+/- Funds"
          name="funds"
          type="number"
          :step=".01"/>
      </div>
      <button class="btn btn-primary w-100 mb-4">
        <span
          v-if="loading"
          class="spinner-border spinner-border-sm">
        </span>
        <span v-else>
          Save
        </span>
      </button>
      <button
        @click.prevent="$emit('closeFormModal')"
        class="btn btn-secondary w-100">
        Close
      </button>
    </Form>
  </div>
</template>

<script>
import { Form } from 'vee-validate'
import SimpleInput from '@/components/Page/Modules/Inputs/SimpleInput'

export default {
  name: 'FundsModule',
  components: {
    SimpleInput,
    Form
  },
  props: ['created', 'loading'],
  methods: {
    submitForm (formData) {
      this.$emit('submitForm', formData)
    },

    /* helpers */
    clickOutside () {
      // if (this.created) {
      //   this.$emit('closeFormModal')
      // }
    }
  }
}
</script>

<style scoped>
.modalForm {
  top: 0;
  right: 0;
  position: fixed;
  height: 100vh;
  width: 300px;
  z-index: 100;
  background: #fff;
  box-shadow: 0 0 7px rgb(0 0 0 / 39%);
  padding: 20px;
  overflow: auto;
}
</style>
