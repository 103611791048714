<template>
  <!-- Edit, View, Open Ticket, Delete -->
  <MasterComponent
    :data="data" />
</template>

<script>
import MasterComponent from '@/components/Page/MasterComponent'
import * as Yup from 'yup'

export default {
  name: 'CouponsView',
  components: {
    MasterComponent
  },
  created () {
    // axios
    //   .get(baseUrl().api + 'user', {
    //     headers: authHeader()
    //   })
    //   .then(res => {
    //     const index = this.data.columns.findIndex((el) => {
    //       return el.slug === 'users'
    //     })
    //     this.data.columns[index].field.options = res.data
    //   })
    //   .catch(err => {
    //     return Promise.reject(err.response.data)
    //   })
    // axios
    //   .get(baseUrl().api + 'service', {
    //     headers: authHeader()
    //   })
    //   .then(res => {
    //     const index = this.data.columns.findIndex((el) => {
    //       return el.slug === 'services'
    //     })
    //     this.data.columns[index].field.options = res.data
    //   })
    //   .catch(err => {
    //     return Promise.reject(err.response.data)
    //   })
  },
  data () {
    const discountType = [
      // {
      //   id: 1,
      //   label: 'Amount',
      //   slug: 'amount'
      // },
      {
        id: 2,
        label: 'Percentage',
        slug: 'percentage'
      }
    ]
    const usageType = [
      {
        id: 1,
        label: 'Global',
        slug: 'global'
      },
      {
        id: 2,
        label: 'User',
        slug: 'user'
      }
    ]
    return {
      data: {
        title: 'Coupons',
        slug: 'coupon',
        config: {
          stats: false,
          datatable: false
        },
        actions: [
          'store',
          'edit',
          'delete'
        ],
        status: discountType,
        filters: [
          {
            id: 1,
            label: 'Code',
            slug: 'code'
          },
          {
            id: 3,
            label: 'Discount',
            slug: 'discount'
          },
          {
            id: 4,
            label: 'Discount Type',
            slug: 'discount_type'
          },
          {
            id: 5,
            label: 'Service',
            slug: 'service'
          }
        ],
        columns: [
          {
            label: 'Code',
            slug: 'code',
            type: 'text',
            field: {
              class: 'col-4',
              name: 'code',
              as: 'input',
              type: 'text',
              rules: Yup.string().required('Code is a required field').nullable()
            }
          },
          {
            label: 'Discount',
            slug: 'discount',
            type: 'number',
            field: {
              class: 'col-4',
              name: 'discount',
              as: 'input',
              type: 'number',
              min: '0',
              step: '1',
              rules: Yup.number().required('Discount is a required field').min(1).nullable()
            }
          },
          {
            label: 'Discount Type',
            slug: 'discount_type',
            type: 'status',
            field: {
              class: 'col-4',
              name: 'discount_type',
              slug: 'discount_type',
              as: 'select',
              options: discountType,
              rules: Yup.number().required('Discount type is a required field').nullable()
            }
          },
          {
            label: 'Min Order Amount',
            slug: 'min',
            type: 'price',
            field: {
              class: 'col-3',
              name: 'min',
              as: 'input',
              type: 'number',
              min: '0',
              step: '.01',
              rules: Yup.number().required('Min is a required field').min(1).nullable()
            }
          },
          {
            label: 'Max Order Amount',
            slug: 'max',
            type: 'price',
            field: {
              class: 'col-3',
              name: 'max',
              as: 'input',
              type: 'number',
              min: '0',
              step: '.01',
              rules: Yup.number().required('Max is a required field').min(1).nullable()
            }
          },
          {
            label: 'Usage Type',
            slug: 'usage_type',
            type: 'text',
            field: {
              class: 'col-3',
              name: 'usage_type',
              slug: 'usage_type',
              as: 'select',
              options: usageType,
              rules: Yup.number().required('Usage type is a required field').nullable()
            }
          },
          {
            label: 'Usages Limit',
            slug: 'usages',
            type: 'number',
            field: {
              class: 'col-3',
              name: 'usages',
              as: 'input',
              type: 'number',
              min: '0',
              step: '1',
              rules: Yup.number().required('Usages limit is a required field').min(1).nullable()
            }
          },
          {
            label: 'Expired At',
            slug: 'expired_at',
            type: 'date',
            field: {
              class: 'col-12',
              name: 'expired_at',
              as: 'input',
              type: 'date',
              rules: Yup.date().required('Expire date is a required field').nullable()
            }
          },
          {
            label: 'Action',
            slug: 'action',
            width: '80px',
            type: 'action',
            actions: [
              {
                id: 1,
                label: 'Edit',
                slug: 'edit'
              },
              {
                id: 2,
                label: 'Delete',
                slug: 'delete'
              }
            ]
          },
          {
            display: false,
            label: 'Users',
            slug: 'users',
            type: 'text',
            field: {
              class: 'col-6',
              name: 'users',
              slug: 'users',
              as: 'multi-select',
              module: 'user',
              pagination: true,
              searchBy: 'email',
              rules: Yup.array().nullable()
            }
          },
          {
            display: false,
            label: 'Services',
            slug: 'services',
            type: 'text',
            field: {
              class: 'col-6',
              name: 'services',
              slug: 'services',
              as: 'multi-select',
              module: 'service',
              pagination: false,
              searchBy: 'name',
              rules: Yup.array().nullable()
            }
          }
        ]
      },
      activeTab: 'All',
      searchBox: false
    }
  }
}
</script>
