import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import UsersView from '../views/UsersView.vue'
import RegisterView from '../views/RegisterView.vue'
import PasswordResetView from '@/views/PasswordResetView'
import ResendVerificationView from '@/views/ResendVerificationView'
import ProfileView from '../views/ProfileView.vue'
import SubscriptionView from '../views/SubscriptionView.vue'
import NotificationView from '../views/NotificationView.vue'
import DashboardView from '../views/DashboardView.vue'
import CategoriesView from '../views/CategoriesView.vue'
import ServicesView from '../views/ServicesView.vue'
import OrdersView from '../views/OrdersView.vue'
import FundsHistoryView from '../views/FundsHistoryView.vue'
import TicketsView from '../views/TicketsView.vue'
import NewTicketView from '../views/NewTicketView.vue'
import AffiliatesView from '../views/AffiliatesView.vue'
import DepositsView from '../views/DepositsView.vue'
import AnnouncementView from '@/views/AnnouncementView'
import SettingView from '@/views/SettingView'
import EmailTemplatesView from '@/views/EmailTemplatesView'
import NewsletterView from '@/views/NewsletterView'
import ShowTicketView from '@/views/ShowTicketView'
import CouponsView from '@/views/CouponsView'
import UserAffiliatesView from '@/views/UserAffiliatesView'
import ActivityView from '@/views/ActivityView'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/orders',
    name: 'orders',
    component: OrdersView
  },

  {
    path: '/users',
    name: 'Users',
    component: UsersView
  },
  {
    path: '/user/affiliates/:id',
    name: 'user.affiliates',
    component: UserAffiliatesView
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView
  },
  {
    path: '/password/reset',
    name: 'Password Reset',
    component: PasswordResetView
  },
  {
    path: '/auth/verify/resend',
    name: 'Resend Verification',
    component: ResendVerificationView
  },
  {
    path: '/profile',
    name: 'user.show',
    component: ProfileView
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: SubscriptionView
  },
  {
    path: '/notification',
    name: 'Notification',
    component: NotificationView
  },
  {
    path: '/',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/categories',
    name: 'Categories',
    component: CategoriesView
  },
  {
    path: '/services',
    name: 'Services',
    component: ServicesView
  },
  {
    path: '/announcements',
    name: 'announcement',
    component: AnnouncementView
  },
  {
    path: '/funds/history',
    name: 'Funds History',
    component: FundsHistoryView
  },
  {
    path: '/tickets',
    name: 'ticket.index',
    component: TicketsView
  },
  {
    path: '/new-ticket',
    name: 'ticket.store',
    component: NewTicketView
  },
  {
    path: '/new-ticket/:user/:uid',
    name: 'ticket.store.params',
    component: NewTicketView
  },
  {
    path: '/ticket/:id',
    name: 'ticket.show',
    component: ShowTicketView
  },
  {
    path: '/deposits',
    name: 'Deposits',
    component: DepositsView
  },
  {
    path: '/affiliates',
    name: 'Affiliates',
    component: AffiliatesView
  },
  {
    path: '/settings',
    name: 'setting',
    component: SettingView
  },
  {
    path: '/templates',
    name: 'emailTemplate',
    component: EmailTemplatesView
  },
  {
    path: '/newsletter',
    name: 'newsletter',
    component: NewsletterView
  },
  {
    path: '/coupons',
    name: 'coupon',
    component: CouponsView
  },
  {
    path: '/activity',
    name: 'activity',
    component: ActivityView
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active'
})

router.beforeEach((to, from, next) => {
  const publicPages = ['login']
  const authRequired = !publicPages.includes(to.name)
  const authNotRequired = publicPages.includes(to.name)
  const loggedIn = localStorage.getItem('user')

  if (authRequired && !loggedIn) {
    next('/login')
  } else if (authNotRequired && loggedIn) {
    next('/')
  } else {
    next()
  }
})

export default router
