<template>
  <!-- Edit, View, Open Ticket, Delete -->
  <MasterComponent
    :data="data" />
</template>

<script>
import MasterComponent from '@/components/Page/MasterComponent'

export default {
  name: 'ActivityView',
  components: {
    MasterComponent
  },
  created () {
  },
  data () {
    return {
      data: {
        title: 'Activity Log',
        slug: 'activity',
        config: {
          stats: false,
          datatable: true
        },
        actions: [
          'delete'
        ],
        status: null,
        filters: [
          {
            id: 1,
            label: 'User',
            slug: 'name'
          },
          {
            id: 3,
            label: 'Event',
            slug: 'event'
          },
          {
            id: 4,
            label: 'Subject',
            slug: 'uid'
          }
        ],
        columns: [
          {
            label: 'ID',
            slug: 'id',
            type: 'text'
          },
          {
            label: 'User',
            slug: 'causer_name',
            type: 'text'
          },
          {
            label: 'Event',
            slug: 'event',
            type: 'text'
          },
          {
            label: 'Subject',
            slug: [
              'subject',
              'uid'
            ],
            type: 'text'
          },
          {
            label: 'Date',
            slug: 'created_at',
            type: 'date',
            data: {
              slug: 'created_at',
              type: 'time24'
            }
          }
        ]
      },
      activeTab: 'All',
      searchBox: false
    }
  }
}
</script>
