<template>
  <div class="resend-verification-view">
    <div class="body-auth">
      <div class="box-window d-flex justify-content-center align-items-center w-100">
        <div>
          <div class="d-flex justify-content-center mb-4">
            <img src="@/assets/logo.svg" alt="Sixerr">
          </div>
          <div class="d-flex justify-content-center mb-4">
            <h1>Resend Verification</h1>
          </div>

          <div class="box box-shadow-default">
            <form>
              <div class="form-group mb-4">
                <label for="exampleInputEmail1" class="form-label">Email</label>
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Your registered email address">
              </div>
              <button type="submit" class="btn btn-primary w-100">Send</button>
            </form>
          </div>

          <h5 class="m-0 mt-3">
            I already have it.
            <router-link
              to="/login"
              class="text-decoration-none forgot-anchor">
              Go Back
            </router-link>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResendVerificationView'
}
</script>

<style scoped>
</style>
