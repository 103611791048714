<template>
  <div
    v-if="!created"
    class="vw-100 vh-100 d-flex justify-content-center align-items-center">
    <span class="spinner-border spinner-border">
    </span>
  </div>
  <div v-else>
    <div
      class="auth-view"
      v-if="!isAuthenticated">
      <router-view/>
      <FooterAuthComponent/>
    </div>
    <div
      v-if="isAuthenticated && currentUser">
      <Header/>
      <Navigation/>
      <div class="main p-3 p-md-5">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import FooterAuthComponent from '@/components/FooterAuthComponent.vue'
import Navigation from '@/components/Navigation.vue'
import Header from '@/components/Header'

export default {
  name: 'App',
  components: {
    Header,
    FooterAuthComponent,
    Navigation
  },
  created () {
    if (this.$store.state.auth.authenticated) {
      this.$store.dispatch('user/show', this.$store.state.auth.user.id).then(() => {
        this.created = true
      }).catch((err) => {
        if (err.status === 401) {
          this.$store.dispatch('auth/logout').then(() => {
            this.$router.push({ name: 'login' })
            this.$router.go()
          })
        }
      })
    } else {
      this.created = true
    }
  },
  data () {
    return {
      created: false
    }
  },
  mounted () {
  },
  computed: {
    isAuthenticated () {
      return this.$store.state.auth.authenticated
    },
    currentUser () {
      return this.$store.state.user.row
    }
  }
}
</script>

<style>
</style>

<style>
.auth-view .body-auth {
  background-color: var(--bg-body-auth);
  /*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0'%3E%3Cstop offset='0' stop-color='%234915F5'/%3E%3Cstop offset='1' stop-color='%232ED47A'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%23F7685B'/%3E%3Cstop offset='1' stop-color='%23FFB946'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='2'%3E%3Cpath transform='translate(0 0)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='4' transform='rotate(0 800 450)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(0 0)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='4'%3E%3Cpath transform='translate(0 0)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='8' transform='rotate(0 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='rotate(0 1400 132)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");*/
  background-attachment: fixed;
  /*background-size: contain;*/
}

.auth-view .box-window {
  height: 100vh;
}

.auth-view img {
  width: 127px;
  /*height: 48px;*/
}

.auth-view h1 {
  font-size: 24px;
  font-weight: bold;
  color: var(--text-title);
}
.auth-view h5 {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-default);
}
.auth-view .box {
  min-width: 448px;
  background-color: var(--white);
  padding: 32px 40px;
  border: 1px solid var(--border-default);
  border-radius: 6px;
}

.auth-view .form-label {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-title);
  margin: unset;
}

.auth-view .forgot-anchor {
  font-size: 14px;
  font-weight: normal;
  color: var(--purple-default);
}

.auth-view .form-check-label {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-default);
}

.auth-view input[type=text],
.auth-view input[type=email],
.auth-view input[type=password] {
  font-size: 14px;
  font-weight: normal;
  border-radius: 6px;
  background-color: #f7f7f7;
  border: 1px solid var(--border-default);
  color: var(--text-default);
}

.auth-view .btn {
  font-size: 15px;
  letter-spacing: 0.2px;
  font-weight: bold;
  border: 1px solid var(--border-default);
  border-radius: 6px;
  background-color: var(--purple-default);
}
</style>
