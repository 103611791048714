<template>
  <!-- Edit, Delete, Funds -->
  <MasterComponent
    :data="data" />
</template>

<script>

import MasterComponent from '@/components/Page/MasterComponent'
import * as Yup from 'yup'
export default {
  name: 'DepositsView',
  components: {
    MasterComponent
  },
  data () {
    const status = [
      {
        id: null,
        label: 'All',
        slug: 'all'
      },
      {
        id: 1,
        label: 'Pending',
        slug: 'pending'
      },
      {
        id: 2,
        label: 'Completed',
        slug: 'completed'
      },
      {
        id: 3,
        label: 'Failed',
        slug: 'failed'
      },
      {
        id: 4,
        label: 'Refunded',
        slug: 'refunded'
      }
    ]
    return {
      data: {
        title: 'Deposits',
        slug: 'transaction',
        config: {
          stats: true,
          datatable: true
        },
        actions: [
          'edit',
          'delete'
        ],
        status: status,
        filters: [
          {
            id: 1,
            label: 'Transaction ID',
            slug: 'uid'
          },
          {
            id: 2,
            label: 'Payment ID',
            slug: 'method_id'
          },
          {
            id: 3,
            label: 'Email',
            slug: 'email'
          },
          {
            id: 4,
            label: 'Payment Method',
            slug: 'method'
          }
        ],
        columns: [
          {
            label: 'Transaction ID',
            slug: 'uid',
            type: 'text'
          },
          {
            label: 'Payment ID',
            slug: 'method_id',
            type: 'text'
          },
          {
            label: 'User',
            slug: [
              'user',
              'name'
            ],
            type: 'text',
            data: {
              slug: [
                'user',
                'email'
              ],
              type: 'text'
            }
          },
          {
            label: 'Method',
            slug: 'method',
            type: 'text'
          },
          {
            label: 'Amount',
            slug: 'amount',
            type: 'price'
          },
          {
            label: 'Dated',
            slug: 'created_at',
            type: 'date',
            data: {
              slug: 'created_at',
              type: 'time24'
            }
          },
          {
            label: 'Status',
            slug: 'status',
            width: '80px',
            type: 'status',
            field: {
              name: 'status',
              slug: 'status',
              as: 'select',
              options: status,
              rules: Yup.number().required('Status is a required field')
            }
          },
          {
            label: 'Action',
            slug: 'action',
            width: '80px',
            type: 'action',
            actions: [
              {
                id: 1,
                label: 'Open Ticket',
                slug: 'open-ticket'
              },
              {
                id: 2,
                label: 'Delete',
                slug: 'delete'
              }
            ]
          }
        ]
      }
    }
  }
}
</script>
