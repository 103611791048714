<template>
  <Field
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    :name="name"
    :rules="rules"
    aria-describedby="input-true input-false input-help"
    aria-invalid="true"
    v-slot="{ meta, field }"
  >
    <label
      :for="name"
      class="form-label">
      {{ label }}
    </label>

    <div v-html="field.value" class="mb-3"></div>

    <textarea
      v-bind="field"
      :name="name"
      :id="name"
      class="form-control"
      :class="{
            'vue-is-valid': meta.valid && meta.touched,
            'vue-is-invalid': !meta.valid && meta.touched,
          }"

      :placeholder="placeholder"
    />
  </Field>
  <ErrorMessage as="div" :name="name" v-slot="{ message }" class="vue-invalid-feedback">
    {{ message }}
  </ErrorMessage>
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate'
export default {
  name: 'HtmlTextareaInput',
  components: {
    Field,
    ErrorMessage
  },
  props: [
    'modelValue',
    'rules',
    'as',
    'label',
    'name',

    'placeholder'
  ]
}
</script>

<style scoped>
textarea {
  font-size: 14px;
  font-weight: normal;
  border-radius: 7px;
  background-color: #f7f7f7;
  border-color: var(--border-default);
  color: var(--text-default);
}

textarea {
  min-height: 500px;
}

textarea:focus {
  font-size: 14px;
  font-weight: normal;
  border-radius: 6px;
  background-color: #f7f7f7;
  border-color: var(--border-default);
  color: var(--text-default);

  outline: unset;
  box-shadow: unset;
}

.form-label {
  font-size: 15px;
  font-weight: bold;
  color: var(--text-title);
}
</style>
