<template>
  <div class="register-view">
    <div class="body-auth">
      <div class="box-window d-flex justify-content-center align-items-center w-100">
        <div>
          <div class="d-flex justify-content-center mb-4">
            <img src="@/assets/logo.svg" alt="Sixerr">
          </div>
          <div class="d-flex justify-content-center mb-4">
            <h1>Sign up an account</h1>
          </div>

          <div class="box box-shadow-default">
            <form>
              <div class="form-group mb-4">
                <label for="inputFullName" class="form-label">Full Name</label>
                <input type="text" class="form-control" id="inputFullName" aria-describedby="inputFullName">
              </div>
              <div class="form-group mb-4">
                <label for="inputEmail" class="form-label">Email address</label>
                <input type="email" class="form-control" id="inputEmail" aria-describedby="inputEmail">
              </div>
              <div class="form-group mb-4">
                <label for="inputPassword" class="form-label">Password</label>
                <input type="password" class="form-control" id="inputPassword">
              </div>
              <div class="form-group form-check mb-4">
                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                <label class="form-check-label" for="exampleCheck1">I accept the <a href="#" class="text-decoration-none forgot-anchor">Terms of Service</a></label>
              </div>
              <button type="submit" class="btn btn-primary w-100">Register</button>
            </form>
          </div>

          <h5 class="d-flex justify-content-center m-0 mt-3">
            Already have an account?
          </h5>
          <router-link
            to="/login"
            class="d-flex justify-content-center text-decoration-none forgot-anchor">
            Sign in
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignUp'
}
</script>

<style scoped>
</style>
