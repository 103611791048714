<template>
  <div class="header-view">
    <nav class="navbar off-navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
        </a>

        <div class="d-flex align-items-center">
          <div class="btn-group">
            <img
              v-if="currentUser.avatar"
              :src="currentUser.avatar.original_url"
              class="rounded-circle dropdown-toggle cursor-pointer"
              width="36"
              height="36"
              alt="user profile picture"
              loading="lazy"
              data-bs-toggle="dropdown"
              data-bs-display="static"
              aria-expanded="false"
            />
            <img
              v-else
              src="@/assets/user-2.svg"
              class="rounded-circle dropdown-toggle cursor-pointer"
              width="36"
              height="36"
              alt="user profile picture"
              loading="lazy"
              data-bs-toggle="dropdown"
              data-bs-display="static"
              aria-expanded="false"
            />
            <ul class="dropdown-menu-profile dropdown-menu dropdown-menu-end">
              <div class="dropdown-header-box p-3 pt-1">
                <li>
                  <h6 class="dropdown-header"></h6>
                  <div class="d-flex align-content-start">

                    <img
                      v-if="currentUser.avatar"
                      :src="currentUser.avatar.original_url"
                      class="rounded-circle dropdown-toggle cursor-pointer"
                      width="50"
                      height="50"
                      alt="user profile picture"
                      loading="lazy"
                      data-bs-toggle="dropdown"
                      data-bs-display="static"
                      aria-expanded="false"
                    />
                    <img
                      v-else
                      src="@/assets/user-2.svg"
                      class="rounded-circle dropdown-toggle"
                      width="50"
                      height="50"
                      alt="Black and White Portrait of a Man"
                      loading="lazy"
                      data-bs-toggle="dropdown"
                      data-bs-display="static"
                      aria-expanded="false"
                    />
                    <div class="ms-3">
                      <h2>{{ this.currentUser.name }}</h2>
                      <h5>{{ this.currentUser.email }}</h5>
                      <span class="cursor-pointer">Administrator</span>
                    </div>
                  </div>
                </li>
              </div>

              <li>
                <router-link
                  :to="{ name: 'user.show' }"
                  class="dropdown-item text-decoration-none">
                  Profile
                </router-link>
              </li>
              <li><a
                class="dropdown-item action"
                @click.prevent="logOut">
                Logout
              </a></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'TopBar',
  created () {
  },
  methods: {
    logOut () {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push({ name: 'login' })
        this.$router.go()
      })
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.user.row
    }
  }
}
</script>

<style scoped>
.navbar-text {
  color: var(--text-orange);
  font-size: 11px;
  font-weight: 600;
}

.navbar {
  /*background-color: var(--bg-major) !important;*/
  /*box-shadow: 0 1px 4px rgb(21 34 50 / 15%);*/
}

button {
  font-size: 16px;
  font-weight: 600;
  color: var(--green-default);
  border: 1px solid var(--green-default);
  padding: 0.075rem 0.75rem;
}

/*notify*/
.dropdown-menu {
  margin-top: 1.35rem;
  padding: unset;
}

.order-btn-circle {
  padding: 7px;
  border-radius: 50%;
  background-color: var(--purple-default);
  cursor: pointer;
}
</style>
