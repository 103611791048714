<template>
  <button
    type="button"
    class="btn btn-status"
    :class="getObject(status).class">
    {{ this.getObject(status).name }}
  </button>
</template>

<script>
export default {
  name: 'StatusButton',
  props: {
    status: {
      type: String,
      required: true
    }
  },
  methods: {
    getObject (status) {
      switch (status) {
        case 'pending':
          return {
            name: 'Pending',
            class: 'btn-pending'
          }
        case 'inProgress':
          return {
            name: 'In Progress',
            class: 'btn-in-progress'
          }
        case 'completed':
          return {
            name: 'Completed',
            class: 'btn-completed'
          }
        case 'paid':
          return {
            name: 'Paid',
            class: 'btn-completed'
          }
        case 'cancelled':
          return {
            name: 'Cancelled',
            class: 'btn-canceled'
          }
        case 'answered':
          return {
            name: 'Answered',
            class: 'btn-answered'
          }
        case 'closed':
          return {
            name: 'Closed',
            class: 'btn-canceled'
          }
        case 'empty':
          return {
            name: 'Empty',
            class: 'btn-canceled'
          }
      }
    }
  }
}
</script>

<style scoped>
.btn-status {
  font-size: 12px;
  font-weight: normal;
  color: white;
  border-radius: 4px;
  width: 80px;
  /*height: 18px;*/
  padding: unset;
}

.btn-status:hover {
  opacity: 0.9;
}

.btn-pending {
  background-color: var(--orange);
  color: var(--white);
  /*border-color: var(--bg-orange);*/
}

.btn-in-progress {
  background-color: var(--purple);
  color: var(--white);
  /*border-color: var(--bg-blue);*/
}

.btn-canceled,
.btn-rejected,
.btn-sent {
  background-color: var(--bg-red);
  color: var(--white);
  /*border-color: var(--bg-red);*/
}

.btn-completed,
.btn-answered,
.btn-added {
  background-color: var(--green-default);
  color: var(--white);
  /*border-color: var(--green-default);*/
}
</style>
