<template>

  <div id="processModal" class="modal fade">
    <div class="modal-dialog modal-confirm">
      <div class="modal-content">
        <div class="modal-header justify-content-center">
          <div class="icon-box">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" width="50px" height="50px">
              <g id="surface112323266">
                <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 83.875 17.25 C 82.546875 17.296875 81.328125 18 80.625 19.125 L 43 76.6875 L 18.625 55.625 C 17.578125 54.539062 16.007812 54.132812 14.5625 54.585938 C 13.125 55.03125 12.054688 56.25 11.804688 57.734375 C 11.554688 59.226562 12.15625 60.726562 13.375 61.625 L 41.25 85.75 C 42.125 86.492188 43.28125 86.820312 44.414062 86.65625 C 45.554688 86.484375 46.5625 85.835938 47.1875 84.875 L 87.375 23.5 C 88.234375 22.242188 88.304688 20.617188 87.5625 19.289062 C 86.820312 17.96875 85.390625 17.179688 83.875 17.25 Z M 83.875 17.25 "/>
              </g>
            </svg>
          </div>
          <button type="button" class="close" data-bs-dismiss="modal" aria-hidden="true">&times;</button>
        </div>
        <div class="modal-body text-center">
          <h4>Notice!</h4>
          <p>Emails sending is in progress kindly wait...</p>
          <button
            class="btn btn-success"
            data-bs-dismiss="modal">
            <span>Ok</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div id="successModal" class="modal fade">
    <div class="modal-dialog modal-confirm">
      <div class="modal-content">
        <div class="modal-header justify-content-center">
          <div class="icon-box">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" width="50px" height="50px">
              <g id="surface112323266">
                <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 83.875 17.25 C 82.546875 17.296875 81.328125 18 80.625 19.125 L 43 76.6875 L 18.625 55.625 C 17.578125 54.539062 16.007812 54.132812 14.5625 54.585938 C 13.125 55.03125 12.054688 56.25 11.804688 57.734375 C 11.554688 59.226562 12.15625 60.726562 13.375 61.625 L 41.25 85.75 C 42.125 86.492188 43.28125 86.820312 44.414062 86.65625 C 45.554688 86.484375 46.5625 85.835938 47.1875 84.875 L 87.375 23.5 C 88.234375 22.242188 88.304688 20.617188 87.5625 19.289062 C 86.820312 17.96875 85.390625 17.179688 83.875 17.25 Z M 83.875 17.25 "/>
              </g>
            </svg>
          </div>
          <button type="button" class="close" data-bs-dismiss="modal" aria-hidden="true">&times;</button>
        </div>
        <div class="modal-body text-center">
          <h4>Great!</h4>
          <p>Emails are sent successfully.</p>
          <button
            class="btn btn-success"
            data-bs-dismiss="modal">
            <span>Send more</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="row">
      <div class="col-12">
        <h1 class="view-name">Newsletter</h1>
      </div>
    </div>

    <div class="master-view">
      <div class="card border-0 mb-3">
        <div class="card-body p-4">
          <div class="row">
            <div
              v-if="error.status"
              class="form-group mb-4">
              <div class="alert alert-danger" role="alert">
                {{ this.error.message }}
              </div>
            </div>
            <div class="mb-4">
              <label for="users" class="form-label">Users</label>
              <VueMultiselect
                v-model="usersSelected"
                id="users"
                label="email"
                track-by="id"
                placeholder="Type to search"
                open-direction="bottom"
                :options="users"
                :multiple="true"
                :searchable="true"
                :loading="loading.search"
                :internal-search="false"
                :clear-on-select="false"
                :close-on-select="false"
                :options-limit="300"
                :limit="3"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="true"
                @search-change="index">
              </VueMultiselect>
            </div>
            <div class="mb-4">
              <label for="users" class="form-label">Subject</label>
              <input
                v-model="input.subject"
                id="subject"
                name="subject"
                class="form-control"
                placeholder="Write your Subject here..."
              />
            </div>
            <div class="mb-4">
              <label for="users" class="form-label">Message</label>
              <textarea
                v-model="input.message"
                id="message"
                name="message"
                class="form-control"
                placeholder="Write your message here..."
              />
            </div>
            <div>

              <button
                @click="store"
                class="btn btn-primary w-100">
                <span
                  v-if="loading.store"
                  class="spinner-border spinner-border-sm">
                </span>
                <span v-else>
                  Mass Email
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import axios from 'axios'
import authHeader from '@/config/authHeader.config'
import baseUrl from '@/config/baseUrl.config'
import Modal from 'bootstrap/js/dist/modal'

export default {
  name: 'NewsletterView',
  components: {
    VueMultiselect
  },
  created () {
  },
  data () {
    return {
      modal: {
        process: null,
        success: null
      },
      processModal: null,
      users: [],
      usersSelected: [],
      loading: {
        store: false,
        search: false
      },
      error: {
        status: false,
        message: null
      },
      input: {
        users: [],
        message: ''
      }
    }
  },
  methods: {
    index (query) {
      this.loading.search = true
      return axios
        .get(baseUrl().api + 'user', {
          headers: authHeader(),
          params: {
            perPage: 25,
            draw: 0,
            filter: null,
            search: query,
            status: null
          }
        })
        .then(res => {
          this.users = res.data.data
          this.loading.search = false
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    },
    store () {
      if (this.loading.store === true) {
        return
      }

      // if (this.input.users.length <= 0) {
      //   this.error.status = true
      //   this.error.message = 'Pick at least 1 user'
      //   return
      // } else
      if (this.input.message === '') {
        this.error.status = true
        this.error.message = 'Write a message'
        return
      }

      this.loading.store = true
      this.error.status = false
      this.error.message = ''
      this.modal.process.show()
      return axios
        .post(baseUrl().api + 'newsletter', this.input, {
          headers: authHeader()
        })
        .then(res => {
          this.modal.process.hide()
          this.modal.success.show()
          this.loading.store = false
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    }
  },
  mounted () {
    this.modal.process = new Modal(document.getElementById('processModal'))
    this.modal.success = new Modal(document.getElementById('successModal'))
  },
  watch: {
    usersSelected: function (newVal, oldVal) {
      this.input.users = []
      newVal.forEach(el => {
        this.input.users.push(el.id)
      })
    }
  }
}
</script>

<style scoped>
.modal-confirm {
  color: #434e65;
  width: 525px;
}
.modal-confirm .modal-content {
  padding: 20px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
}
.modal-confirm .modal-header {
  background: #47c9a2;
  border-bottom: none;
  position: relative;
  text-align: center;
  margin: -20px -20px 0;
  border-radius: 5px 5px 0 0;
  padding: 35px;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 36px;
  margin: 10px 0;
}
.modal-confirm .form-control, .modal-confirm .btn {
  min-height: 40px;
  border-radius: 3px;
}
.modal-confirm .close {
  font-size: 24px;
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
  text-shadow: none;
  opacity: 0.5;
  border: unset;
  background: unset;
}
.modal-confirm .close:hover {
  opacity: 0.8;
}
.modal-confirm .icon-box {
  color: #fff;
  width: 95px;
  height: 95px;
  display: inline-block;
  border-radius: 50%;
  z-index: 9;
  border: 5px solid #fff;
  padding: 15px;
  text-align: center;
}
.modal-confirm .icon-box i {
  font-size: 64px;
  margin: -4px 0 0 -4px;
}
.modal-confirm.modal-dialog {
  margin-top: 80px;
}
.modal-confirm .btn, .modal-confirm .btn:active {
  color: #fff;
  border-radius: 4px;
  background: #eeb711 !important;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border-radius: 30px;
  margin-top: 10px;
  padding: 6px 20px;
  border: none;
}
.modal-confirm .btn:hover, .modal-confirm .btn:focus {
  background: #eda645 !important;
  outline: none;
}
.modal-confirm .btn span {
  margin: 1px 3px 0;
  float: left;
}
.modal-confirm .btn i {
  margin-left: 1px;
  font-size: 20px;
  float: right;
}
.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}
</style>
