<template>
  <nav aria-label="Page navigation example" class="d-flex justify-content-between align-items-center p-0">
    <span class="page-stats">{{pagination.from}} - {{pagination.to}} of {{pagination.total}}</span>
    <select class="perPage" @change="$emit('perPage', $event.target.value);">
      <option value="5" selected>5</option>
      <option value="10">10</option>
      <option value="25">25</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>
    <ul class="pagination m-0">
      <li
        :class="(pagination.currentPage === 1) ? 'disabled' : null"
        class="page-item cursor-pointer no-select">
        <a
          @click="$emit('prev');"
          class="page-link"
          tabindex="-1"
          aria-disabled="true">
          Previous
        </a>
      </li>
      <li
        :class="(pagination.currentPage === pagination.lastPage) ? 'disabled' : null"
        class="page-item cursor-pointer no-select">
        <a
          @click="$emit('next');"
          class="page-link"
          tabindex="-1"
          aria-disabled="true">
          Next
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'PaginationModule',
  props: ['pagination']
}
</script>

<style scoped>
.perPage {
  border: unset;
}
a.page-link:focus,
select.perPage:focus-visible {
  outline: unset;
  box-shadow: unset;
}
</style>
