<template>
  <div
    :class="customClass">
<!--    :value="value"-->
<!--    @input="$emit('update:modelValue', $event.target.value)"-->
    <Field
      v-model="value"
      :name="name"
      :rules="rules"
      aria-describedby="input-true input-false input-help"
      aria-invalid="true"
      v-slot="{ meta }"
    >
      <label
        :for="name"
        class="form-label">
        {{ label }}
      </label>
      <VueMultiselect
        v-if="pagination"
        :class="{
                'vue-is-valid': meta.valid && meta.touched,
                'vue-is-invalid': !meta.valid && meta.touched,
              }"
        v-model="value"
        :name="name"
        :id="name"
        :label="searchBy"
        track-by="id"
        placeholder="Type to search"
        open-direction="bottom"
        :options="options"
        :multiple="true"
        :searchable="true"
        :loading="loading"
        :internal-search="false"
        :clear-on-select="false"
        :close-on-select="false"
        :options-limit="300"
        :limit="3"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="true"
        @search-change="index">
      </VueMultiselect>
      <VueMultiselect
        v-else
        :class="{
                'vue-is-valid': meta.valid && meta.touched,
                'vue-is-invalid': !meta.valid && meta.touched,
              }"
        v-model="value"
        :name="name"
        :id="name"
        :label="searchBy"
        track-by="id"
        open-direction="bottom"
        :options="options"
        :multiple="true"
        :searchable="true"
        :loading="loading"
        :internal-search="true"
        :clear-on-select="false"
        :close-on-select="false"
        :options-limit="300"
        :limit="3"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="true">
      </VueMultiselect>
    </Field>
    <ErrorMessage as="div" :name="name" v-slot="{ message }" class="vue-invalid-feedback">
      {{ message }}
    </ErrorMessage>

  </div>
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate'
import VueMultiselect from 'vue-multiselect'
import axios from 'axios'
import baseUrl from '@/config/baseUrl.config'
import authHeader from '@/config/authHeader.config'

export default {
  name: 'SelectMultipleInput',
  components: {
    Field,
    ErrorMessage,
    VueMultiselect
  },
  props: [
    'modelValue',
    'rules',
    'as',
    'name',
    'placeholder',
    'module',
    'pagination',
    'searchBy',
    'label',
    'customClass'
  ],
  created () {
    this.value = this.modelValue
    if (this.pagination === false) {
      this.list()
    }
  },
  data () {
    return {
      loading: false,
      options: [],
      value: []
    }
  },
  methods: {
    index (query) {
      this.loading = true
      return axios
        .get(baseUrl().api + this.module, {
          headers: authHeader(),
          params: {
            perPage: 25,
            draw: 0,
            filter: null,
            search: query,
            status: null
          }
        })
        .then(res => {
          this.options = res.data.data
          this.loading = false
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    },
    list () {
      this.loading = true
      return axios
        .get(baseUrl().api + this.module, {
          headers: authHeader()
        })
        .then(res => {
          this.options = res.data
          this.loading = false
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    }
  }
}
</script>

<style scoped>
select {
  font-size: 14px;
  font-weight: normal;
  border-radius: 7px;
  background-color: #f7f7f7;
  border-color: var(--border-default);
  color: var(--text-default);
}
.custom-background select {
  background-color: #fff;
}
.custom-padding select {
  padding: 0.625rem 0.75rem;
}

select:focus {
  font-size: 14px;
  font-weight: normal;
  border-radius: 6px;
  background-color: #f7f7f7;
  border-color: var(--border-default);
  color: var(--text-default);

  outline: unset;
  box-shadow: unset;
}

.form-label {
  font-size: 15px;
  font-weight: bold;
  color: var(--text-title);
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
