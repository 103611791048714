<template>
  <section class="single-ticket-view">
    <div
      v-if="!created"
      class="main-loading">
      <div class="spinner-border" role="status">
      </div>
    </div>
    <div
      v-else
      class="row">
      <div class="row">
        <div class="col-12">
          <h1 class="view-name">User Affiliates</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card border-0 mb-3">
            <div class="card-body">
              <div v-if="rows.length > 0">
                <div class="table-responsive">
                  <table class="table align-middle">
                    <thead>
                    <tr>
                      <th scope="col">Month</th>
                      <th scope="col">Clicks</th>
                      <th scope="col">Registered</th>
                      <th scope="col">Commission</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      v-for="(row, idx) in rows"
                      :key="idx">
                      <td>{{ row.date }}</td>
                      <td>{{ row.clicks }}</td>
                      <td>{{ row.registered }}</td>
                      <td>{{ centsToDollar(row.commission) }}</td>
                      <td>
                        <StatusButton :status="row.status"/>
                      </td>
                      <td>
                        <button
                          @click="pay(row.date)"
                          class="btn btn-sm btn-primary">Pay</button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                v-if="rows.length <= 0"
                class="d-flex justify-content-center align-items-center" style="min-height: 431px;">
                <h5>No data found</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import StatusButton from '@/components/StatusButton'
import axios from 'axios'
import authHeader from '@/config/authHeader.config'
import baseUrl from '@/config/baseUrl.config'

export default {
  name: 'UserAffiliatesView',
  components: {
    StatusButton
  },
  created () {
    axios
      .get(baseUrl().api + 'affiliate/' + this.$route.params.id, {
        headers: authHeader()
      })
      .then(res => {
        this.rows = res.data
        this.created = true
        console.log(res.data)

        return Promise.resolve(res.data)
      })
      .catch(err => {
        // if (err.status === 404) {
        //   this.$router.push({ name: 'ticket.index' })
        // }
        return Promise.reject(err)
      })
  },
  data () {
    return {
      rows: null,
      created: false,
      loadingForm: false,
      error: {
        status: false,
        message: null
      },
      input: {
        message: '',
        attachment: null
      }
    }
  },
  methods: {
    pay (date) {
      console.log('pay (date)', date)
      this.$fire({
        title: 'Are you sure?',
        type: 'info',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return axios
            .post(baseUrl().api + 'affiliate/' + this.$route.params.id, {
              _method: 'put',
              date: date
            }, {
              headers: authHeader()
            })
            .then((res) => {
              return true
            }).catch((err) => {
              this.$alert(
                `Request failed: ${err.response.data.message}`,
                'Error!',
                'error'
              )
              return false
            })
        },
        allowOutsideClick: false
      }).then((res) => {
        if (res.value) {
          this.$alert(
            'Affiliate commission paid to user.',
            'Success!',
            'success'
          )
        }
      })
      // this.loadingForm = true
      //
      // if (this.input.message === '') {
      //   this.error.status = true
      //   this.error.message = 'The message field is required.'
      //   this.loadingForm = false
      // } else if (this.input.attachment !== null && this.input.attachment.size > 2 * 1024 * 1024) {
      //   this.error.status = true
      //   this.error.message = 'The attachment must not be greater than 2 megabytes.'
      //   this.loadingForm = false
      // } else {
      //   this.error.status = false
      //   this.error.message = null
      //
      //   const formData = this.input
      //   console.log('formData', formData)
      //
      //   return axios
      //     .post(baseUrl().api + 'ticket/' + this.$route.params.id + '/reply', formData, {
      //       headers: authHeader(true)
      //     })
      //     .then(res => {
      //       this.input.message = ''
      //       this.input.attachment = null
      //       this.$refs.attachment.value = null
      //       this.row = res.data
      //       this.loadingForm = false
      //       console.log(res)
      //
      //       const replyId = res.data.replies[res.data.replies.length - 1].id
      //
      //       this.$nextTick(() => {
      //         window.scrollTo({
      //           top: document.getElementById(`reply-box-${replyId}`).offsetTop,
      //           left: 0,
      //           behavior: 'smooth'
      //         })
      //       })
      //       return Promise.resolve(res.data)
      //     })
      //     .catch(error => {
      //       error = error.response.data
      //       this.error.message = ''
      //
      //       if (typeof error.errors !== 'undefined') {
      //         const errors = error.errors
      //         Object.keys(errors).forEach((errorKey) => {
      //           errors[errorKey].forEach((errVal, errIdx) => {
      //             this.error.message += (this.error.message === '') ? errVal : ', ' + errVal
      //           })
      //         })
      //       } else if (typeof error.message !== 'undefined') {
      //         this.error.message = error.message
      //       }
      //
      //       this.error.status = true
      //       this.loadingForm = false
      //       return Promise.reject(error)
      //     })
      // }
    },

    /* Static global helpers */
    focusAttachment () {
      const el = this.$refs.attachment
      this.$nextTick(() => {
        el.click()
      })
    },

    /* Static global helpers */
    centsToDollar (cents) {
      return this.$centsToDollar(cents)
    },
    timestampToDate (timestamp) {
      return this.$timestampToDate(timestamp)
    },
    timestampToMonthDay (timestamp) {
      return this.$timestampToMonthDay(timestamp)
    },
    timestampToTime12 (timestamp) {
      return this.$timestampToTime12(timestamp)
    },
    timestampToTime24 (timestamp) {
      return this.$timestampToTime24(timestamp)
    }
  }
}
</script>

<style scoped>
.replies {
  /*max-height: 50vh;*/
  /*overflow-x: hidden;*/
}

.customer-person-box {
  border-radius: 6px;
}
.customer-person-box .card-header-custom {
  color: var(--text-default);
}
.support-person-box {
  border-radius: 6px;
  background-color: #E7F9F1;
}
.card-header-custom {
  padding: 0 20px 20px 0;
}
.customer-person-box .card-header-custom,
.support-person-box .card-header-custom {
  font-size: 14px;
  line-height: 28px;
}

.card-header-sub {
  line-height: 28px;
}
.submitted-on {
  font-size: 10px;
  font-weight: normal;
  color: var(--text-default);
  letter-spacing: 0.2px;
}

.message-send {
  font-size: 14px;
  font-weight: bold;
  border: unset;
  background: unset;
}

.support-person-box .card-header-custom {
  color: #3ace7c;
}

h3 {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-title);
}
.title-order {
  font-size: 16px;
}

.title-order.text-default {
  font-size: 14px;
  color: var(--text-default);
}

h4 {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-default);
}

h5 {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-default);
  margin: unset;
}

h6 {
  font-size: 12px;
  font-weight: normal;
  color: var(--text-default);
  margin: unset;
}

.attachment {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-default);
  margin: unset;
  text-decoration: none;
  cursor: pointer;
  display: block;
  clear: both;
  margin-bottom: 5px;
}

input[type="number"],
input[type="text"] {
  padding: 0.625rem 0.75rem;
}

select,
input,
textarea {
  font-size: 14px;
  font-weight: normal;
  border-radius: 7px;
  background-color: #f7f7f7;
  border-color: var(--border-default);
  color: var(--text-default);
}

input[type="checkbox"] {
  margin: unset;
}

textarea {
  min-height: 80px;
}

select:focus,
input:focus,
textarea:focus {
  font-size: 14px;
  font-weight: normal;
  border-radius: 7px;
  background-color: #f7f7f7;
  border-color: var(--border-default);
  color: var(--text-default);

  outline: unset;
  box-shadow: unset;
}

input[type="checkbox"] {
  margin-right: 10px;
}

.form-label {
  font-size: 15px;
  font-weight: bold;
  color: var(--text-title);
}

.position-sticky {
  top: 20px;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=file] {
  background: none;
  text-indent: -100px;
  border: unset;
}
</style>
