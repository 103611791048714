<template>
  <div class="row">
    <div
      v-for="(row, idx) in rows"
      :key="row.slug"
      @click="() => { if (row.id !== 0) { $emit('status', row.id) } }"
      :class="[getClassByIndex(idx), (row.id !== 0) && 'cursor-pointer']">
      <div class="card border-0 mb-3 card-anchor">
        <div class="card-header bg-transparent border-0">{{ row.label }}</div>
        <div class="card-body">
          <h5
            v-if="row.type === 'price'"
            class="card-title">
            {{ centsToDollar(row.value) }}
          </h5>
          <h5
            v-else
            class="card-title">
            {{ row.value }}
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatsModule',
  props: ['rows'],
  methods: {
    /* helpers */
    getClassByIndex (index) {
      switch (this.rows.length) {
        case 1:
          return 'col-12'
        case 2:
          return 'col-6'
        case 3:
          return 'col-4'
        case 4:
          return 'col-3'
        case 5:
          return (index < 4) ? 'col-2' : 'col-4'
        case 6:
          return 'col-2'
        case 7:
          return (index < 4) ? 'col-3' : 'col-4'
      }
    },

    /* Static global helpers */
    centsToDollar (cents) {
      return this.$centsToDollar(cents)
    }
  }
}
</script>

<style scoped>

</style>
