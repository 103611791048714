<template>
  <div class="card-header bg-transparent">
    <div class="d-flex justify-content-between">
      <div class="card-header-button card-header-button-left">
        <router-link
          :to="{ name: 'user.show' }"
          class="text-decoration-none"
          v-slot="{ isActive, isExactActive }">
            <span
              :class="[isActive && 'active', isExactActive && 'active']">
              Profile
            </span>
        </router-link>
<!--        <router-link-->
<!--          to="/notification"-->
<!--          class="text-decoration-none"-->
<!--          v-slot="{ route, isActive, isExactActive }">-->
<!--            <span-->
<!--              :class="[isActive && 'active', isExactActive && 'active']">-->
<!--              {{ route.name }}-->
<!--            </span>-->
<!--        </router-link>-->
      </div>
    </div>
  </div>
  <!--  <hr class="mt-0 mb-4">-->
</template>

<script>
export default {
  name: 'HeaderSettings'
}
</script>

<style scoped>

</style>
