<template>
  <div class="subscription-view">
    <div class="row">
      <div class="col-12">
        <h1 class="view-name">Howdy!</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 col-xxl-4">
        <router-link
          to="/funds/history"
          class="text-decoration-none"
          aria-current="page">
          <div class="card border-0 mb-3 card-anchor">
            <div class="card-header bg-transparent border-0">Membership</div>
            <div class="card-body">
              <h5 class="card-title">10%</h5>
              <p class="card-text d-flex align-items-center">
                active discount
              </p>
            </div>
            <div class="card-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none" stroke="#CCDDFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line></svg>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 col-xxl-4">
        <div class="card border-0 mb-3 card-anchor">
          <div class="card-header bg-transparent border-0">Savings</div>
          <div class="card-body">
            <h5 class="card-title">$320.20</h5>
            <p class="card-text d-flex align-items-center">
              total saved
            </p>
          </div>
          <div class="card-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none" stroke="#CCDDFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 col-xxl-4">
        <router-link
          to="/funds/history"
          class="text-decoration-none"
          aria-current="page">
          <div class="card border-0 mb-3 card-anchor">
            <div class="card-header bg-transparent border-0">Joined</div>
            <div class="card-body">
              <h5 class="card-title">May, 2020</h5>
              <p class="card-text d-flex align-items-center">
                member since
              </p>
            </div>
            <div class="card-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none" stroke="#CCDDFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card border-0 mb-3">
          <div class="card-header card-header-custom bg-transparent border-0">Choose a membership</div>
          <div class="card-header card-header-sub bg-transparent border-0">Subscribe to become a member and enjoy flat discount across all services, priority support and fast processing of your orders.</div>
          <div class="card-body pt-4 pb-4">
            <div class="row">
              <div class="col-4">
                <div class="card card-membership border-0">
                  <div class="card-body pt-20">
                    <h5 class="title">Hobby</h5>
                    <h5 class="price mb-4">$12 / monthly</h5>
                    <h5 class="info mb-4">The hobby plan allows you to manage a single server; however, you may still create unlimited sites and deployments.</h5>
                    <h5 class="discount">5%</h5>
                    <h5 class="discount-info ms-2">Flat Discount</h5>
                    <a href="#" class="btn btn-custom btn-order p-3 w-100 mt-5" data-bs-toggle="modal" data-bs-target="#addFundsModal">Subscribe</a>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="card card-membership border-0">
                  <div class="card-body pt-20">
                    <h5 class="title">Hobby</h5>
                    <h5 class="price mb-4">$12 / monthly</h5>
                    <h5 class="info mb-4">The hobby plan allows you to manage a single server; however, you may still create unlimited sites and deployments.</h5>
                    <h5 class="discount">5%</h5>
                    <h5 class="discount-info ms-2">Flat Discount</h5>
                    <a href="#" class="btn btn-custom btn-order p-3 w-100 mt-5" data-bs-toggle="modal" data-bs-target="#addFundsModal">Subscribe</a>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="card card-membership border-0">
                  <div class="card-body pt-20">
                    <h5 class="title">Hobby</h5>
                    <h5 class="price mb-4">$12 / monthly</h5>
                    <h5 class="info mb-4">The hobby plan allows you to manage a single server; however, you may still create unlimited sites and deployments.</h5>
                    <h5 class="discount">5%</h5>
                    <h5 class="discount-info ms-2">Flat Discount</h5>
                    <a href="#" class="btn btn-custom btn-order p-3 w-100 mt-5" data-bs-toggle="modal" data-bs-target="#addFundsModal">Subscribe</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionView'
}
</script>

<style scoped>
.card-membership {
  background-color: var(--bg-body)!important;
  border: 3px solid var(--border-default)!important;
}
.card-membership:hover {
  background-color: var(--white)!important;
  box-shadow: 0 30px 100px rgb(0 44 63 / 10%);
}
.title {
  font-size: 18px;
  font-weight: bold;
  color: var(--text-title);
}
.price {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-default);
}
.info {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-default);
  line-height: 22px;
}
.discount {
  font-size: 60px;
  font-weight: bold;
  color: var(--green-default);
}
.discount-info {
  font-size: 12px;
  font-weight: normal;
  color: var(--text-default);
}
.btn-custom {
  font-size: 16px;
  text-transform: uppercase;
  padding: 8px 16px!important;
}

</style>
