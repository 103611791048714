import axios from 'axios'
import authHeader from '@/config/authHeader.config'
import baseUrl from '@/config/baseUrl.config'

export const user = {
  namespaced: true,
  state: {
    row: null
  },
  actions: {
    show ({ commit }, id) {
      return axios
        .get(baseUrl().api + 'user/' + id, {
          headers: authHeader()
        })
        .then(res => {
          commit('populateRow', res.data)
          return Promise.resolve(res)
        })
        .catch(err => {
          return Promise.reject(err.response)
        })
    },
    update ({ commit }, { id, data }) {
      console.log('data', data)
      return axios
        .post(`${baseUrl().api}user/${id}`, data, {
          headers: authHeader(true)
        })
        .then(res => {
          commit('populateRow', res.data)
          return Promise.resolve(res)
        })
        .catch(err => {
          return Promise.reject(err.response.data)
        })
    },
    lazyUpdate ({ commit }, data) {
      commit('populateRow', data)
    }
  },
  mutations: {
    populateRow (state, data) {
      console.log(data)
      state.row = data
    }
  }
}
