<template>
  <td class="td-1"><span></span></td>
</template>

<script>
export default {
  name: 'LoadingModule'
}
</script>

<style scoped>
@-webkit-keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }
  100% {
    background-position: 250px 0;
  }
}

td {
  height: 50px;
  vertical-align: middle;
  padding: 8px;
}

td span {
  display: block;
  height: 12px;
  margin: 21.5px 0;
  background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

td.td-2 span {
  background-color: rgba(0, 0, 0, 0.15);
}

td.td-3 span {
  height: 12px;
  background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

td.td-5 span {
  background-color: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 30px;
}
</style>
