<template>
  <!-- Reply, Close Ticket -->
  <MasterComponent
    :data="data" />
</template>

<script>

import MasterComponent from '@/components/Page/MasterComponent'
import * as Yup from 'yup'
export default {
  name: 'TicketsView',
  components: {
    MasterComponent
  },
  data () {
    const status = [
      {
        id: null,
        label: 'All',
        slug: 'all'
      },
      {
        id: 1,
        label: 'Pending',
        slug: 'pending'
      },
      {
        id: 2,
        label: 'Answered',
        slug: 'answered'
      },
      {
        id: 3,
        label: 'Closed',
        slug: 'closed'
      }
    ]
    const priority = [
      {
        id: null,
        label: 'None',
        slug: 'none'
      },
      {
        id: 1,
        label: 'Low',
        slug: 'low'
      },
      {
        id: 2,
        label: 'Medium',
        slug: 'medium'
      },
      {
        id: 3,
        label: 'High',
        slug: 'high'
      }
    ]
    return {
      data: {
        title: 'Tickets',
        slug: 'ticket',
        config: {
          stats: true,
          datatable: true
        },
        actions: [
          'edit'
        ],
        status: status,
        filters: [
          {
            id: 1,
            label: 'Subject',
            slug: 'subject'
          },
          {
            id: 2,
            label: 'Email',
            slug: 'email'
          },
          {
            id: 3,
            label: 'Ticket ID',
            slug: 'uid'
          },
          {
            id: 4,
            label: 'Department ID',
            slug: 'department_id'
          },
          {
            id: 5,
            label: 'Priority',
            slug: 'priority'
          }
        ],
        columns: [
          {
            clickable: true,
            label: 'Subject',
            slug: 'subject',
            type: 'text',
            field: {
              name: 'subject',
              as: 'input',
              rules: Yup.string().required('Subject is a required field')
            }
          },
          {
            label: 'Department ID',
            slug: 'department_id',
            type: 'text'
          },
          {
            label: 'Ticket ID',
            slug: 'uid',
            type: 'text'
          },
          {
            label: 'User',
            slug: [
              'user',
              'name'
            ],
            type: 'text',
            data: {
              slug: [
                'user',
                'email'
              ],
              type: 'text'
            }
          },
          {
            label: 'Submission Date',
            slug: 'created_at',
            type: 'date',
            data: {
              slug: 'created_at',
              type: 'time24'
            }
          },
          {
            label: 'Last Reply',
            slug: 'updated_at',
            type: 'date',
            data: {
              slug: 'created_at',
              type: 'time24'
            }
          },
          {
            label: 'Priority',
            slug: 'priority',
            width: '80px',
            type: 'status',
            field: {
              name: 'priority',
              slug: 'priority',
              as: 'select',
              options: priority,
              rules: Yup.number().required('Priority is a required field')
            }
          },
          {
            label: 'Status',
            slug: 'status',
            width: '80px',
            type: 'status',
            field: {
              name: 'status',
              slug: 'status',
              as: 'select',
              options: status,
              rules: Yup.number().required('Status is a required field')
            }
          },
          {
            label: 'Action',
            slug: 'action',
            width: '80px',
            type: 'action',
            actions: [
              {
                id: 1,
                label: 'Reply',
                slug: 'reply'
              },
              {
                id: 1,
                label: 'Edit',
                slug: 'edit'
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
  }
}
</script>
