<template>
  <div class="notification-view">
    <div class="card border-0 mb-3">

      <HeaderSettings/>

      <hr class="mt-0 mb-4">
      <div class="card-body">

        <div class="row mb-5">
          <div class="col-6">
            <h5 class="pb-3">Order Notifications</h5>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="blogsCheck">
              <label class="form-check-label" for="blogsCheck">Pending</label>
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="blogsCheck">
              <label class="form-check-label" for="blogsCheck">In progress</label>
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="blogsCheck">
              <label class="form-check-label" for="blogsCheck">Completed</label>
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="blogsCheck">
              <label class="form-check-label" for="blogsCheck">Cancelled</label>
            </div>
          </div>
          <div class="col-6">
            <h5 class="pb-3">Funds Notifications</h5>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="blogsCheck">
              <label class="form-check-label" for="blogsCheck">Completed</label>
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="blogsCheck">
              <label class="form-check-label" for="blogsCheck">Refunded</label>
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="blogsCheck">
              <label class="form-check-label" for="blogsCheck">Failed</label>
            </div>
          </div>
          <div class="col-6 mt-5">
            <h5 class="pb-3">Ticket Notifications</h5>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="blogsCheck">
              <label class="form-check-label" for="blogsCheck">Opened</label>
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="blogsCheck">
              <label class="form-check-label" for="blogsCheck">Answered</label>
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="blogsCheck">
              <label class="form-check-label" for="blogsCheck">Closed</label>
            </div>
          </div>
          <div class="col-6 mt-5">
            <h5 class="pb-3">Security Notifications</h5>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="blogsCheck">
              <label class="form-check-label" for="blogsCheck">Password reset</label>
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="eventsCheck">
              <label class="form-check-label" for="eventsCheck">Signed-in</label>
            </div>
          </div>
          <div class="col-6 mt-5">
            <h5 class="pb-3">Account Notifications</h5>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="blogsCheck">
              <label class="form-check-label" for="blogsCheck">Suspended</label>
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="eventsCheck">
              <label class="form-check-label" for="eventsCheck">Banned</label>
            </div>
          </div>
          <div class="col-6 mt-5">
            <h5 class="pb-3">Affiliate Notifications</h5>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="blogsCheck">
              <label class="form-check-label" for="blogsCheck">New sign-up</label>
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="eventsCheck">
              <label class="form-check-label" for="eventsCheck">Commission paid</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h5 class="pb-3">Newsletter</h5>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="newsletterCheck">
              <label class="form-check-label" for="newsletterCheck">Subscribe to our newsletter?</label>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button type="button" class="btn btn-custom btn-action">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderSettings from '@/components/HeaderSettings'

export default {
  name: 'ProfileView',
  components: {
    HeaderSettings
  }
}
</script>

<style scoped>

h5 {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-default);
}

.form-check-label {
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
  color: var(--text-default);
}
</style>
