<template>
  <!-- Edit, Delete, Funds -->
  <MasterComponent
    :data="data" />
</template>

<script>
import MasterComponent from '@/components/Page/MasterComponent'
import * as Yup from 'yup'
export default {
  name: 'AffiliatesView',
  components: {
    MasterComponent
  },
  data () {
    const status = [
      {
        id: null,
        label: 'All',
        slug: 'all'
      },
      {
        id: 1,
        label: 'Verified',
        slug: 'verified'
      },
      {
        id: 2,
        label: 'Unverified',
        slug: 'unVerified'
      },
      {
        id: 3,
        label: 'Suspended',
        slug: 'suspended'
      },
      {
        id: 4,
        label: 'Banned',
        slug: 'banned'
      }
    ]
    return {
      data: {
        title: 'Affiliates',
        slug: 'affiliate',
        config: {
          stats: true,
          datatable: true
        },
        actions: [
          'delete'
        ],
        status: status,
        filters: [
          {
            id: 1,
            label: 'Id',
            slug: 'id'
          },
          {
            id: 2,
            label: 'Name',
            slug: 'name'
          },
          {
            id: 3,
            label: 'Email',
            slug: 'email'
          }
        ],
        columns: [
          {
            label: 'ID',
            slug: 'id',
            type: 'text'
          },
          {
            label: 'User',
            slug: 'name',
            type: 'text',
            data: {
              slug: 'email',
              type: 'text'
            }
          },
          {
            label: 'Joined',
            slug: 'created_at',
            type: 'date',
            data: {
              slug: 'created_at',
              type: 'time24'
            }
          },
          {
            label: 'Current Balance',
            slug: 'cash',
            type: 'price'
          },
          {
            label: 'Total Deposits',
            slug: 'total_deposits',
            type: 'price'
          },
          {
            label: 'Total Expense',
            slug: 'total_expense',
            type: 'price'
          },
          {
            label: 'Total Orders',
            slug: 'total_orders',
            type: 'text'
          },
          {
            label: 'Avg order Value',
            slug: 'average_order_value',
            type: 'price'
          },
          {
            label: 'Referred By',
            slug: [
              'referral',
              'name'
            ],
            type: 'text',
            data: {
              slug: [
                'referral',
                'email'
              ],
              type: 'text'
            }
          },
          {
            label: 'Membership',
            slug: 'active_membership',
            type: 'text'
          },
          {
            label: 'Status',
            slug: 'status',
            width: '80px',
            type: 'status',
            field: {
              name: 'status',
              slug: 'status',
              as: 'select',
              options: status,
              rules: Yup.number().required('Status is a required field')
            }
          },
          {
            label: 'Action',
            slug: 'action',
            width: '80px',
            type: 'action',
            actions: [
              {
                id: 1,
                label: '+/- Funds',
                slug: 'funds'
              },
              {
                id: 3,
                label: 'Delete',
                slug: 'delete'
              }
            ]
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
.ref-percentage {
  font-size: 20px;
  font-weight: bold;
  color: var(--green-default);
}
.btn-custom {
  font-size: 16px;
}
</style>
