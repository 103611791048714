<template>
  <!-- Add, Edit, Delete -->
  <MasterComponent
    :data="data" />
</template>

<script>
import MasterComponent from '@/components/Page/MasterComponent'
import * as Yup from 'yup'
export default {
  name: 'EmailTemplatesView',
  components: {
    MasterComponent
  },
  created () {
  },
  data () {
    const status = [
      {
        id: null,
        label: 'All',
        slug: 'all'
      },
      {
        id: 1,
        label: 'Publish',
        slug: 'enable'
      },
      {
        id: 0,
        label: 'Un-publish',
        slug: 'disable'
      }
    ]
    return {
      data: {
        title: 'Email Templates',
        slug: 'email_template',
        config: {
          stats: false,
          datatable: false
        },
        status: status,
        actions: [
          'edit'
        ],
        columns: [
          {
            label: 'Mailable',
            slug: 'mailable',
            type: 'text'
          },
          {
            label: 'Subject',
            slug: 'subject',
            type: 'text',
            field: {
              name: 'subject',
              as: 'input',
              type: 'text',
              rules: Yup.string().required('Subject is a required field').nullable()
            }
          },
          {
            label: 'Text Template',
            slug: 'text_template',
            type: 'text',
            field: {
              name: 'text_template',
              as: 'input',
              type: 'text',
              rules: Yup.string().required('Text template is a required field').nullable()
            }
          },
          {
            label: 'Status',
            slug: 'status',
            width: '80px',
            type: 'status',
            field: {
              name: 'status',
              slug: 'status',
              as: 'select',
              options: status,
              rules: Yup.number().required('Status is a required field').nullable()
            }
          },
          {
            label: 'Action',
            slug: 'action',
            width: '80px',
            type: 'action',
            actions: [
              {
                id: 1,
                label: 'Edit',
                slug: 'edit'
              }
            ]
          },

          /* hidden columns for form build */
          {
            display: false,
            label: 'Html Template',
            slug: 'html_template',
            type: 'text',
            field: {
              name: 'html_template',
              as: 'html-textarea',
              type: 'text',
              rules: Yup.string().required('Html template is a required field').nullable()
            }
          }
        ]
      },
      activeTab: 'All',
      searchBox: false
    }
  }
}
</script>
