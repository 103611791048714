<template>
  <div class="new-ticket-view">
    <div class="row">
      <div class="col-12">
        <h1 class="view-name">Open Ticket</h1>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <div class="card border-0 mb-3">
            <div class="card-body pt-20">
              <Form @submit="onSubmit" :validation-schema="schema" class="needs-validation">
                <div
                  v-if="success.status"
                  class="form-group mb-4">
                  <div class="alert alert-success" role="alert">
                    {{ success.message }}
                  </div>
                </div>

                <div
                  v-if="error.status"
                  class="form-group mb-4">
                  <div class="alert alert-danger" role="alert">
                    {{ error.message }}
                  </div>
                </div>

                <div class="mb-3">
                  <SimpleInput
                    v-model="input.subject"
                    type="text"
                    label="Subject"
                    name="subject"

                    placeholder="Title of the ticket"
                  />
                </div>

                <div class="row mb-3">
                  <div class="col-12 col-lg-4 mb-4 mb-lg-0">
                    <SimpleInput
                      v-model="input.department_id"
                      type="text"
                      label="ID"
                      name="department_id"

                      placeholder="Order Id, Transaction Id"
                    />
                  </div>
                  <div class="col-12 col-lg-4 mb-4 mb-lg-0">
                    <SelectInput
                      v-model="input.department"
                      name="department"
                      :options="[{ id: 1, name: 'Sale' }, { id: 2, name: 'Support' }]"

                      label="Department"
                      class="custom-padding"
                    />
                  </div>
                  <div class="col-12 col-lg-4">
                    <SelectInput
                      v-model="input.priority"
                      name="priority"
                      :options="[{ id: 1, name: 'Low' }, { id: 2, name: 'Medium' }, { id: 3, name: 'High' }]"

                      label="Priority"
                      class="custom-padding"
                    />
                  </div>
                </div>

                <div class="mb-3">
                  <TextareaInput
                    v-model="input.message"
                    label="Message"
                    name="message"

                    placeholder="Type your message here.."
                  />
                </div>

                <div class="send-message">
                  <div class="d-flex justify-content-between align-items-center ms-3 mt-3 me-3">
                    <div class="d-flex justify-content-start align-items-center">
                      <div
                        @click="focusAttachment"
                        class="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                             stroke="#495c77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                          <path
                            d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
                        </svg>
                      </div>
                      <input
                        @change="(e) => { (e.target.files.length > 0) ? input.attachment = e.target.files[0] : input.attachment = null }"
                        id="attachment"
                        name="attachment"
                        ref="attachment"
                        type="file"
                        accept="image/*"
                        class="form-control"
                      />
                    </div>

                    <button
                      class="message-send text-green">
                      <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm">
                      </span>
                      <span
                        v-show="!loading">
                        Send
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form } from 'vee-validate'
import * as yup from 'yup'
import axios from 'axios'
import baseUrl from '@/config/baseUrl.config'
import authHeader from '@/config/authHeader.config'
import TextareaInput from '@/components/Page/Modules/Inputs/TextareaInput'
import SimpleInput from '@/components/Page/Modules/Inputs/SimpleInput'
import SelectInput from '@/components/Page/Modules/Inputs/SelectInput'

export default {
  name: 'NewTicketView',
  components: {
    SelectInput,
    SimpleInput,
    TextareaInput,
    Form
  },
  created () {
    if (this.$route.params.user !== undefined) {
      this.input.user_id = this.$route.params.user
    }
    if (this.$route.params.uid !== undefined) {
      this.input.department_id = this.$route.params.uid
    }
  },
  data () {
    return {
      schema: yup.object({
        subject: yup.string().required('Subject is a required field'),
        department: yup.string().required('Department is a required field'),
        department_id: yup.string().required('Id is a required field'),
        priority: yup.string().required('Priority is a required field'),
        message: yup.string().required('Message is a required field')
      }),
      loading: false,
      error: {
        status: false,
        message: null
      },
      success: {
        status: false,
        message: null
      },
      input: {
        subject: '',
        department: '',
        user_id: '',
        department_id: '',
        priority: '',
        message: '',
        attachment: null
      }
    }
  },
  methods: {
    onSubmit (values) {
      this.loading = true
      this.error.status = false
      this.error.message = null

      if (this.input.attachment !== null && this.input.attachment.size > 2 * 1024 * 1024) {
        this.error.status = true
        this.error.message = 'The attachment must not be greater than 2 megabytes.'
        this.loading = false
      } else {
        const formData = this.input

        console.log('new.ticket.view.onSubmit (values)', values)
        return axios
          .post(baseUrl().api + 'ticket', formData, {
            headers: authHeader(true)
          })
          .then(res => {
            this.input = {
              subject: '',
              department: '',
              department_id: '',
              priority: '',
              message: '',
              attachment: null
            }
            this.$refs.attachment.value = null
            this.loading = false
            this.error.status = false
            this.error.message = null
            this.success.status = true
            this.success.message = 'Ticket opened successfully'
            return Promise.resolve(res.data)
          })
          .catch(error => {
            error = error.response.data
            this.error.message = ''

            if (typeof error.errors !== 'undefined') {
              const errors = error.errors
              Object.keys(errors).forEach((errorKey) => {
                errors[errorKey].forEach((errVal, errIdx) => {
                  this.error.message += (this.error.message === '') ? errVal : ', ' + errVal
                })
              })
            } else if (typeof error.message !== 'undefined') {
              this.error.message = error.message
            }

            this.error.status = true
            this.loading = false
            return Promise.reject(error)
          })
      }
    }
  }
}
</script>

<style scoped>
.message-send {
  font-size: 14px;
  font-weight: bold;
  border: unset;
  background: unset;
}

h3 {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-title);
}

input[type=file] {
  text-indent: -104px;
  border: unset;
}
</style>
