<template>
  <!-- Edit, View, Open Ticket, Delete -->
  <MasterComponent
    :data="data" />
</template>

<script>
import MasterComponent from '@/components/Page/MasterComponent'
import * as Yup from 'yup'
import axios from 'axios'
import baseUrl from '@/config/baseUrl.config'
import authHeader from '@/config/authHeader.config'

export default {
  name: 'OrdersView',
  components: {
    MasterComponent
  },
  created () {
    axios
      .get(baseUrl().api + 'service', {
        headers: authHeader()
      })
      .then(res => {
        this.data.columns[4].field.options = res.data
      })
      .catch(err => {
        return Promise.reject(err.response.data)
      })
  },
  data () {
    const status = [
      {
        id: null,
        label: 'All',
        slug: 'all'
      },
      {
        id: 1,
        label: 'Pending',
        slug: 'pending'
      },
      {
        id: 2,
        label: 'In Progress',
        slug: 'inProgress'
      },
      {
        id: 3,
        label: 'Completed',
        slug: 'completed'
      },
      {
        id: 4,
        label: 'Cancelled',
        slug: 'cancelled'
      }
    ]
    return {
      data: {
        title: 'Orders',
        slug: 'order',
        config: {
          stats: true,
          datatable: true
        },
        actions: [
          'edit',
          'delete'
        ],
        status: status,
        filters: [
          {
            id: 1,
            label: 'Email',
            slug: 'email'
          },
          {
            id: 3,
            label: 'Order ID',
            slug: 'uid'
          },
          {
            id: 4,
            label: 'Url',
            slug: 'url'
          },
          {
            id: 5,
            label: 'Service',
            slug: 'service'
          }
        ],
        columns: [
          {
            label: 'ID',
            slug: 'uid',
            type: 'text'
          },
          {
            label: 'Date',
            slug: 'created_at',
            type: 'date'
          },
          {
            label: 'User',
            slug: [
              'user',
              'name'
            ],
            type: 'text',
            data: {
              slug: [
                'user',
                'email'
              ],
              type: 'text'
            }
          },
          {
            label: 'Category',
            slug: [
              'service',
              'category',
              'name'
            ],
            type: 'text'
          },
          {
            class: 'col-12',
            label: 'Service',
            slug: [
              'service',
              'name'
            ],
            type: 'text',
            field: {
              name: 'service_id',
              slug: [
                'service',
                'id'
              ],
              as: 'select',
              options: [],
              rules: Yup.number().required('Service is a required field')
            }
          },
          {
            class: 'col-12',
            label: 'Url',
            slug: 'url',
            type: 'text',
            field: {
              name: 'url',
              as: 'input',
              type: 'text',
              rules: Yup.string().required('Url is a required field')
              // .matches(
              //   /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
              //   'Invalid url entered'
              // )
            }
          },
          {
            class: 'col-4',
            label: 'Quantity',
            slug: 'quantity',
            type: 'number',
            field: {
              name: 'quantity',
              as: 'input',
              type: 'number',
              min: '0',
              step: '1',
              rules: Yup.number().required('Quantity is a required field').min(1)
            }
          },
          {
            class: 'col-4',
            label: 'Cost',
            slug: 'cost',
            type: 'price',
            field: {
              name: 'cost',
              as: 'input',
              type: 'number',
              min: '0',
              step: '.01',
              rules: Yup.number().required('Cost is a required field').min(1)
            }
          },
          {
            editable: true,
            class: 'col-4',
            label: 'Start Count',
            slug: 'start_count',
            type: 'number',
            field: {
              name: 'start_count',
              as: 'input',
              type: 'number',
              min: '0',
              step: '1',
              rules: Yup.number().nullable()
            }
          },
          {
            class: 'col-12',
            label: 'Notes',
            slug: 'notes',
            type: 'text',
            field: {
              name: 'notes',
              as: 'textarea',
              rules: Yup.string().nullable()
            }
          },
          {
            class: 'col-12',
            label: 'Status',
            slug: 'status',
            width: '80px',
            type: 'status',
            field: {
              name: 'status',
              slug: 'status',
              as: 'select',
              options: status,
              rules: Yup.number().required('Status is a required field')
            }
          },
          {
            label: 'Action',
            slug: 'action',
            width: '80px',
            type: 'action',
            actions: [
              {
                id: 1,
                label: 'Open Ticket',
                slug: 'open-ticket'
              },
              {
                id: 2,
                label: 'Edit',
                slug: 'edit'
              },
              {
                id: 3,
                label: 'Delete',
                slug: 'delete'
              }
            ]
          }
        ]
      },
      activeTab: 'All',
      searchBox: false
    }
  }
}
</script>
