<template>
  <Field
    v-model="content"
    @input="$emit('update:content', $event.target.value)"
    :name="name"
    :rules="rules"
    aria-describedby="input-true input-false input-help"
    aria-invalid="true"
    v-slot="{ meta, field }"
  >
    <label
      :for="name"
      class="form-label">
      {{ label }}
    </label>
    <QuillEditor
      @update:content="contentUpdated"
      :name="name"
      :id="name"
      :content="getContent"
      content-type="html"
      theme="snow"
      toolbar="full" />
    <input
      v-model="content"
      v-bind="field"
      :name="name"
      :id="name"
      type="text"
      class="form-control d-none"
      :class="{
            'vue-is-valid': meta.valid && meta.touched,
            'vue-is-invalid': !meta.valid && meta.touched,
          }"
    />
  </Field>
  <ErrorMessage as="div" :name="name" v-slot="{ message }" class="vue-invalid-feedback">
    {{ message }}
  </ErrorMessage>

</template>

<script>
import { Field, ErrorMessage } from 'vee-validate'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
export default {
  name: 'RichTextEditor',
  props: [
    'modelValue',
    'rules',
    'as',
    'name',
    'label'
  ],
  created () {
    this.content = this.modelValue
  },
  data () {
    return {
      content: null
    }
  },
  components: { Field, ErrorMessage, QuillEditor },
  methods: {
    contentUpdated (content) {
      this.content = content
    }
  },
  computed: {
    getContent () {
      return this.modelValue
    }
  }
}
</script>

<style scoped>
</style>
