<template>
  <div class="d-flex justify-content-between" style="width: calc(100% - 430px);">
    <div class="input-group">
      <img click="searchBoxToggle()" class="input-group-text cursor-pointer" src="@/assets/ic-search.svg">
      <input
        ref="searchInput"
        @change="$emit('search', $event.target.value)"
        type="text"
        class="form-control"
        placeholder="Search Here...">
    </div>
    <div class="card-header-button">
      <select
        @change="$emit('filter', $event.target.value)"
        class="form-select filter"
        id="inputFilter"
        aria-label="inputFilter">
        <option selected value="">Filter</option>
        <option
          v-for="filter in filters"
          :key="filter.id"
          :value="filter.slug">
          {{ filter.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchModule',
  props: [
    'filters'
  ]
}
</script>

<style scoped>

</style>
