<template>
  <div class="table-responsive">
    <table class="table align-middle">
      <thead>
      <tr>
        <th scope="col">Browser</th>
        <th scope="col">Numbers</th>
        <th scope="col">Percentage</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Google Chrome</td>
        <td>4000</td>
        <td>10%</td>
      </tr>
      <tr>
        <td>Google Chrome</td>
        <td>4000</td>
        <td>10%</td>
      </tr>
      <tr>
        <td>Google Chrome</td>
        <td>4000</td>
        <td>10%</td>
      </tr>
      <tr>
        <td>Google Chrome</td>
        <td>4000</td>
        <td>10%</td>
      </tr>
      <tr>
        <td>Google Chrome</td>
        <td>4000</td>
        <td>10%</td>
      </tr>
      </tbody>
    </table>
  </div>
  <router-link
    to="/orders"
    class="d-flex justify-content-end text-decoration-none pe-3 anchor"
    aria-current="page">
    View All
  </router-link>
</template>

<script>
export default {
  name: 'TopBrowsers'
}
</script>

<style scoped>

</style>
