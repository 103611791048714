<template>
  <div class="login-view">
    <div class="body-auth">
      <div class="box-window d-flex justify-content-center align-items-center w-100">
        <div>
          <div class="d-flex justify-content-center mb-4">
            <img src="@/assets/logo.svg" alt="not found">
          </div>
          <div class="d-flex justify-content-center mb-4">
            <h1>Sign in to your account</h1>
          </div>

          <div class="box box-shadow-default">
            <form @submit.prevent="submitForm">
              <div
                v-if="this.error.status"
                class="form-group mb-4">
                <div class="alert alert-danger" role="alert">
                  <strong>Error!</strong> {{ this.error.message }}
                </div>
              </div>
              <div class="form-group mb-4">
                <label for="email" class="form-label">Email address</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  v-model="input.email">
              </div>
              <div class="form-group mb-4">
                <label for="password" class="form-label">Password</label>
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  v-model="input.password">
              </div>
              <div class="d-flex justify-content-between align-items-center mb-4">
                <div class="form-group form-check">
                  <input type="checkbox" class="form-check-input" id="remember">
                  <label class="form-check-label" for="remember">Remember me</label>
                </div>
              </div>
              <button
                type="submit"
                class="btn btn-primary w-100">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm">
              </span>
                <span
                  v-show="!loading">
                Login
              </span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginView',
  data () {
    return {
      loading: false,
      error: {
        status: false,
        message: null
      },
      input: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    submitForm () {
      this.loading = true

      if (this.input.email === '' || this.input.password === '') {
        this.error.status = true
        this.error.message = 'Email address or password can not be empty'
        this.loading = false
      } else if (this.validateEmail(this.input.email)) {
        this.error.status = true
        this.error.message = 'Email address is not valid'
        this.loading = false
      } else {
        this.error.status = false
        this.error.message = null

        this.$store.dispatch('auth/login', this.input).then(
          (res) => {
            this.$store.dispatch('user/show', res.id).then(() => {
              this.$router.push({ name: 'dashboard' })
            })
          },
          (error) => {
            this.error.message = ''

            if (typeof error.errors !== 'undefined') {
              const errors = error.errors
              Object.keys(errors).forEach((errorKey) => {
                errors[errorKey].forEach((errVal, errIdx) => {
                  this.error.message += (this.error.message === '') ? errVal : ', ' + errVal
                })
              })
            } else if (typeof error.message !== 'undefined') {
              this.error.message = error.message
            }

            this.error.status = true
            this.loading = false
          }
        )
      }
    },
    validateEmail (email) {
      return !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
    }
  }
}
</script>

<style scoped>
</style>
